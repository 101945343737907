<template>
	<div class="display-flex flex-row-wrap gap-m">
		<div class="p-xs grey1-bg">grey1</div>
		<div class="p-xs grey2-bg">grey2</div>
		<div class="p-xs grey3-bg">grey3</div>
		<div class="p-xs grey4-bg">grey4</div>
		<div class="p-xs blue1-bg">blue1</div>
		<div class="p-xs blue2-bg">blue2</div>
		<div class="p-xs green1-bg">green1</div>
		<div class="p-xs danger-background">danger-color</div>
		<div class="p-xs warning-bg">warning-color</div>
		<div class="p-xs primary-bg">custom-primary</div>
	</div>
</template>

<script>
export default {
	name: "ColorExamples"
};
</script>
