<template>
	<div class="full-width full-height overflow-y-scroll">
		<div class="full-width full-height flex-center-content" v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="asset_wind_power_systems">
			<div
				v-for="(energy_system, index) in asset_wind_power_systems"
				:key="energy_system.id"
				class="p-t-xs p-b-xs border-b cursor-pointer"
				@click="toggle_visibility(index)"
			>
				<div class="display-flex-space-between">
					<div class="flex-center-content gap-s">
						<div v-if="energy_system.has_provider">
							<EnergyProviderImg
								:provider_name="energy_system.provider_data.provider_name"
								:height_in_px="25"
							/>
						</div>
						<div>
							{{ energy_system.name }}
						</div>
					</div>

					<div class="flex-center-content">
						<Icon
							:icon="visible_systems[index] ? 'chevron-up' : 'chevron-down'"
							size="20"
						/>
					</div>
				</div>

				<div v-if="visible_systems[index]">
					<div id="wind-power-data-table" class="p-t" ref="tables">
						<div v-for="field in power_data_fields" class="display-flex gap-l">
							<div>
								<span class="font-bold">
									{{
										$t("entities.energy_system.wind_power_data." + field.key)
									}}
								</span>
							</div>
							<div>
								<span>{{ energy_system.wind_power_data[field.key] }}</span>
								<span v-if="field.unit"> {{ field.unit }}</span>
							</div>
						</div>
					</div>

					<div class="flex-horizontal-right gap-s">
						<router-link
							:to="{
								name: 'user-energy-system-update',
								params: {
									system_id: energy_system.id
								}
							}"
						>
							<button class="btn btn-primary">
								{{ $t("general.edit") }}
							</button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import EnergyProviderImg from "../../../../sub_components/location-components/EnergyProviderImg.vue";

import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder.js";
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();

export default {
	name: "AssetWindPowerData",
	components: {
		Loader,
		Alert,
		EnergyProviderImg
	},
	props: {
		asset_id: String
	},
	data() {
		return {
			loading: true,
			error: null,
			asset_wind_power_systems: null,
			power_data_fields: [
				{
					key: "peak_power",
					unit: "kWp"
				},
				{
					key: "nominal_power",
					unit: "kW"
				},
				{
					key: "wind_turbines"
				},
				{
					key: "wind_turbine_model"
				},
				{
					key: "wind_turbine_nominal_power",
					unit: "kWp"
				},
				{
					key: "hub_height",
					unit: "m"
				},
				{
					key: "rotor_diameter",
					unit: "m"
				},
				{
					key: "losses",
					unit: "%"
				}
			],
			visible_systems: []
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_energy_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(
					this.asset_id,
					true
				);

			this.asset_wind_power_systems = asset_energy_systems.filter(
				(energy_system) =>
					energy_system.generation_type &&
					energy_system.generation_type == "wind_power"
			);

			this.visible_systems = new Array(
				this.asset_wind_power_systems.length
			).fill(false);

			if (this.visible_systems.length == 1) this.toggle_visibility(0);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		toggle_visibility(index) {
			this.$set(this.visible_systems, index, !this.visible_systems[index]);
		}
	}
};
</script>

<style scoped>
#wind-power-data-table {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 15px;
}
</style>
