import RestInterface from "./rest-interface.js";
import { html_to_pdf_service_api_key } from "@config";

export default class {
	constructor() {
		this.rest_interface = new RestInterface(false);
	}

	async get_pdf_url_from_html(html_content, file_name) {
		const file_content_type = "text/html";
		const html_file = this._get_file_from_content(
			html_content,
			file_name,
			file_content_type
		);

		const result = await this.rest_interface.post(
			"https://v2.convertapi.com/convert/html/to/pdf?Secret=" +
				html_to_pdf_service_api_key +
				"&StoreFile=true",
			{
				body_params: {
					PageSize: "a4",
					BreakBeforeElements: ".report-page",
					Scale: 128,
					ViewportWidth: 1024,
					FileName: file_name
				},
				files: {
					File: html_file
				}
			}
		);

		const pdf_url = result.Files[0].Url;
		return pdf_url;
	}

	_get_file_from_content(content, file_name, file_content_type) {
		const blob = new Blob([content], { type: file_content_type });
		const file = new File([blob], file_name + ".html", {
			type: file_content_type,
			lastModified: Date.now()
		});

		return file;
	}
}
