<template>
	<div>
		<div v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else class="display-flex gap-s">
			<div
				v-if="resolution_options.length > 1"
				id="measurements-resolution-selector"
				class="display-flex gap-s grey3-bg font-bold p-xxs rounded-s grey1-color"
			>
				<div
					v-for="(data, index) in resolution_options"
					v-bind:class="{
						active: selected_resolution == data.key
					}"
					v-on:click="selected_resolution = data.key"
					class="rounded-s cursor-pointer flex-center-content"
				>
					<div>
						{{ data.label }}
					</div>
				</div>
			</div>

			<div v-if="selected_dates_range && selected_resolution != 'yearly'">
				<div class="grey3-bg">
					<Datepicker
						v-if="datepicker_min_view == 'day'"
						v-model="selected_dates_range"
						:is_range="true"
						:max_range_days="6"
						:min_view="datepicker_min_view"
						:allow_from="datepicker_allow_from"
						:allow_to="datepicker_allow_to"
					/>
					<Datepicker
						v-else
						v-model="selected_dates_range"
						:min_view="datepicker_min_view"
						:allow_from="datepicker_allow_from"
						:allow_to="datepicker_allow_to"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Datepicker from "@form_components/Datepicker.vue";

import moment from "moment";

import AssetEnergyDataAvailableDateRangeFinderClass from "../../../../../../../src/asset-energy-data/application/asset-energy-data-available-date-range-finder.js";
const AssetEnergyDataAvailableDateRangeFinder =
	new AssetEnergyDataAvailableDateRangeFinderClass();

export default {
	name: "MeasurementsFilter",
	components: {
		Loader,
		Datepicker
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_energy_data_available_date_range: null,

			selected_resolution: "optimal",
			selected_dates_range: null,
			asset_has_energy_measurements_data: null,
			asset_has_energy_forecast_data: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_id = this.$route.params.asset_id;

			this.asset_energy_data_available_date_range =
				await AssetEnergyDataAvailableDateRangeFinder.get_asset_energy_data_available_date_range(
					asset_id
				);

			this.asset_has_energy_measurements_data =
				this.asset_energy_data_available_date_range.energy_measurements
					.hourly ||
				this.asset_energy_data_available_date_range.energy_measurements.daily ||
				this.asset_energy_data_available_date_range.energy_measurements.monthly
					? true
					: false;

			this.asset_has_energy_forecast_data = this
				.asset_energy_data_available_date_range.energy_forecast.hourly
				? true
				: false;

			if (
				this.asset_energy_data_available_date_range.energy_measurements
					.hourly ||
				this.asset_energy_data_available_date_range.energy_forecast.hourly
			) {
				this.selected_resolution = "optimal";

				let selected_date_to = this.datepicker_allow_to;
				if (
					moment(selected_date_to).isAfter(new Date()) &&
					moment(selected_date_to).diff(new Date(), "days") > 4
				) {
					selected_date_to = moment().add(4, "days").toDate();
				}

				const selected_date_from = moment(selected_date_to)
					.subtract(this.asset_has_energy_forecast_data ? 5 : 3, "days")
					.toDate();

				this.selected_dates_range = [selected_date_from, selected_date_to];
			} else if (
				this.asset_energy_data_available_date_range.energy_measurements.monthly
			) {
				this.selected_resolution = "daily";

				this.selected_dates_range =
					this.asset_energy_data_available_date_range.energy_measurements.hourly.to;
			}
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	computed: {
		resolution_options() {
			const posible_options = [
				{
					key: "optimal",
					resolution: "hourly",
					label: this.$t("asset_energy_panel.day")
				},
				{
					key: "daily",
					resolution: "daily",
					label: this.$t("asset_energy_panel.month")
				},
				{
					key: "monthly",
					resolution: "monthly",
					label: this.$t("asset_energy_panel.year")
				},
				{
					key: "yearly",
					resolution: "monthly",
					label: this.$t("asset_energy_panel.total")
				}
			];

			let result = [];

			posible_options.forEach((item) => {
				if (
					(this.asset_has_energy_measurements_data &&
						this.asset_energy_data_available_date_range.energy_measurements[
							item.resolution
						]) ||
					(this.asset_has_energy_forecast_data &&
						this.asset_energy_data_available_date_range.energy_forecast[
							item.resolution
						])
				) {
					result.push(item);
				}
			});

			return result;
		},
		datepicker_min_view() {
			switch (this.selected_resolution) {
				case "optimal":
					return "day";
					break;
				case "daily":
					return "month";
					break;
				case "monthly":
					return "year";
					break;
			}
		},
		datepicker_allow_from() {
			if (!this.asset_energy_data_available_date_range) return null;

			switch (this.selected_resolution) {
				case "optimal":
					const energy_measurements_from =
						this.asset_has_energy_measurements_data &&
						this.asset_energy_data_available_date_range.energy_measurements
							.hourly
							? this.asset_energy_data_available_date_range.energy_measurements
									.hourly.from
							: null;

					const energy_forecast_from =
						this.asset_has_energy_forecast_data &&
						this.asset_energy_data_available_date_range.energy_forecast.hourly
							? this.asset_energy_data_available_date_range.energy_forecast
									.hourly.from
							: null;

					if (energy_measurements_from && energy_forecast_from) {
						return moment(energy_measurements_from).isBefore(
							energy_forecast_from
						)
							? energy_measurements_from
							: energy_forecast_from;
					} else if (energy_measurements_from) {
						return energy_measurements_from;
					} else if (energy_forecast_from) {
						return energy_forecast_from;
					} else {
						return null;
					}
					break;
				case "daily":
					return this.asset_energy_data_available_date_range.energy_measurements
						.daily.from;
					break;
				case "monthly":
					return this.asset_energy_data_available_date_range.energy_measurements
						.monthly.from;
					break;
			}
		},
		datepicker_allow_to() {
			if (!this.asset_energy_data_available_date_range) return null;

			switch (this.selected_resolution) {
				case "optimal":
					const energy_measurements_to =
						this.asset_has_energy_measurements_data &&
						this.asset_energy_data_available_date_range.energy_measurements
							.hourly
							? this.asset_energy_data_available_date_range.energy_measurements
									.hourly.to
							: null;

					const energy_forecast_to =
						this.asset_has_energy_forecast_data &&
						this.asset_energy_data_available_date_range.energy_forecast.hourly
							? this.asset_energy_data_available_date_range.energy_forecast
									.hourly.to
							: null;

					if (energy_measurements_to && energy_forecast_to) {
						return moment(energy_measurements_to).isBefore(energy_forecast_to)
							? energy_measurements_to
							: energy_forecast_to;
					} else if (energy_measurements_to) {
						return energy_measurements_to;
					} else if (energy_forecast_to) {
						return energy_forecast_to;
					} else {
						return null;
					}
					break;
				case "daily":
					return this.asset_energy_data_available_date_range.energy_measurements
						.daily.to;
					break;
				case "monthly":
					return this.asset_energy_data_available_date_range.energy_measurements
						.monthly.to;
					break;
			}
		}
	},
	methods: {
		emit_filter() {
			this.$emit(
				"filter_emited",
				this.no_data
					? { no_data: true }
					: {
							resolution: this.selected_resolution,
							date: this.selected_dates_range,
							asset_has_energy_forecast_data:
								this.asset_has_energy_forecast_data
					  }
			);
		}
	},
	watch: {
		selected_dates_range(new_value, old_value) {
			this.emit_filter();
		},
		selected_resolution(new_value, old_value) {
			if (old_value == "optimal")
				this.selected_dates_range = this.selected_dates_range[1];
			else if (new_value == "optimal") {
				var from;
				var to = this.selected_dates_range;

				if (moment(to).isAfter(this.datepicker_allow_to)) {
					to = this.datepicker_allow_to;
				} else if (moment(to).isBefore(this.datepicker_allow_from)) {
					to = this.datepicker_allow_from;
				}

				from = to;

				this.selected_dates_range = [from, to];
			}

			if (new_value == "yearly") this.emit_filter();
		}
	}
};
</script>

<style scoped>
#measurements-resolution-selector {
	border: 1px solid #ced4da;
}
#measurements-resolution-selector > div {
	padding: 0 8px;
}
#measurements-resolution-selector > div.active {
	background-color: var(--grey1);
	color: white;
}
</style>
