<template>
	<div
		class="full-widdth full-height white-bg p-sm rounded-s box-shadow relative overflow-visible"
	>
		<div
			v-if="show_data_export_tool == true"
			class="absolute full-width full-height"
			id="asset-energy-measurements-tool"
		>
			<div class="full-width full-height flex-center-content">
				<AssetEnergyMeasurementsExport
					v-on:close="show_data_export_tool = false"
				/>
			</div>
		</div>
		<div v-if="no_data_in_asset">
			<Alert message="general.errors.no_data_available" type="info" />
		</div>
		<div v-else class="flex-auto-full-height-content">
			<div class="header">
				<div class="display-flex-space-between">
					<div class="p-t-xxs display-flex">
						<MeasurementsFilter v-on:filter_emited="load_measurements" />
						<div class="m-l">
							<button
								class="btn btn-grey"
								v-on:click="show_data_export_tool = true"
							>
								<Icon icon="file-export" />
							</button>
						</div>
					</div>
					<div v-if="measurements_aggregation">
						<EnergyMeasurementsAggregation
							:measurements_aggregation="measurements_aggregation"
						/>
					</div>
				</div>
			</div>
			<div class="content">
				<div v-if="loading" class="flex-center-content full-height">
					<Loader />
				</div>
				<div v-else-if="error" class="m-t">
					<Alert :message="error" />
				</div>
				<div v-else-if="measurements" class="p-t" style="height: 100%">
					<div v-if="measurements.length == 0">
						<Alert type="info" message="pv_main_panels.no_energy_data" />
					</div>
					<div v-else>
						<div v-if="selected_resolution == 'optimal'">
							<LineChart
								height="400px"
								:series="measurements"
								:variables_data="variables_data"
								legend_positon="top"
								:default_visible_variables="default_visible_variables"
								:single_y_axis="
									Object.keys(variables_data).length == 1 ? true : false
								"
								:y_axis_formatter="
									Object.keys(variables_data).length == 1
										? (value) =>
												$format_number(value, 0) +
												' ' +
												variables_data[Object.keys(variables_data)[0]].unit
										: undefined
								"
								:x_axis_annotations="[
									{
										date: new Date(),
										color: 'var(--grey1)',
										multilingual_text: 'general.now'
									}
								]"
							/>
							<AssetEnergyForecastAccuracyMetrics
								v-if="asset_has_energy_forecast"
								:asset_id="asset.id"
								:date_from="used_filters.date[0]"
								:date_to="used_filters.date[1]"
							/>
						</div>

						<StackedColumnChart
							v-else
							height="400px"
							:series="measurements"
							:series_data="variables_data"
							legend_positon="top"
							:default_visible_variables="default_visible_variables"
							:y_axis_formatter="(value) => $format_number(value, 2) + ' kWh'"
							:x_axis_formatter="
								(time) => {
									switch (selected_resolution) {
										case 'daily':
											return $format_date_to_month_day_number(time);
											break;
										case 'monthly':
											return $format_date_to_month_number(time);
											break;
										case 'yearly':
											return $format_date_to_year_number(time);
											break;
										default:
											return time;
											break;
									}
								}
							"
							:x_axis_tooltip_formatter="
								(time) => {
									switch (selected_resolution) {
										case 'daily':
											return $format_date_to_day(time);
											break;
										case 'monthly':
											return $format_date_to_month(time);
											break;
										case 'yearly':
											return $format_date_to_year_number(time);
											break;
										default:
											return time;
											break;
									}
								}
							"
							:csv_category_column_name_formatter="
								() => {
									switch (selected_resolution) {
										case 'daily':
											return $t('asset_energy_panel.day');
											break;
										case 'monthly':
											return $t('asset_energy_panel.month');
											break;
										case 'yearly':
											return $t('asset_energy_panel.year');
											break;
										default:
											return '';
									}
								}
							"
							:export_file_name_formatter="
								() => {
									switch (selected_resolution) {
										case 'daily':
											return (
												asset.name +
												'_' +
												$format_date_to_month(used_filters.date)
											);
											break;
										case 'monthly':
											return (
												asset.name +
												'_' +
												$format_date_to_year_number(used_filters.date)
											);
											break;
										case 'yearly':
											return asset.name + '_' + $t('asset_energy_panel.total');
											break;
										default:
											return '';
									}
								}
							"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import MeasurementsFilter from "./MeasurementsFilter.vue";
import AssetEnergyMeasurementsExport from "./AssetEnergyMeasurementsExport.vue";
import EnergyMeasurementsAggregation from "./EnergyMeasurementsAggregation.vue";
import LineChart from "@sub_components/charts/LineChart2.vue";
import StackedColumnChart from "@sub_components/charts/StackedColumnChart.vue";
import AssetEnergyForecastAccuracyMetrics from "./asset-energy-forecast-accuracy-metrics/AssetEnergyForecastAccuracyMetrics.vue";

import PvMeasuredVariables from "@entities/variables/pv-measured-data";
import WeatherMeasurementsVariablesUtil from "../../../../../utils/weather-measurements-variables-util.js";

import moment from "moment";
import AssetFinderClass from "../../../../../../../src/asset/application/asset-finder.js";
import AssetDayOptimalPowerFinderClass from "@src/asset-energy-data/application/asset-day-optimal-power-finder.js";
import AssetDailyAggregatedEnergyFinderClass from "@src/asset-energy-data/application/asset-daily-aggregated-energy-finder.js";
import AssetDailyEnergyFinderClass from "@src/asset-energy-data/application/asset-daily-energy-finder.js";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder.js";
import AssetDayOptimalWeatherMeasurementsFinderClass from "@src/asset-weather/application/asset-day-optimal-weather-measurements-finder.js";
import AssetEnergyForecastFinderClass from "@src/forecast/application/asset-energy-forecast-finder.js";

const AssetFinder = new AssetFinderClass();
const AssetDayOptimalPowerFinder = new AssetDayOptimalPowerFinderClass();
const AssetDailyAggregatedEnergyFinder =
	new AssetDailyAggregatedEnergyFinderClass();
const AssetDailyEnergyFinder = new AssetDailyEnergyFinderClass();
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();
const AssetDayOptimalWeatherMeasurementsFinder =
	new AssetDayOptimalWeatherMeasurementsFinderClass();
const AssetEnergyForecastFinder = new AssetEnergyForecastFinderClass();

export default {
	name: "AssetEnergyDataMainPanelMeasurements",
	components: {
		Loader,
		Alert,
		MeasurementsFilter,
		AssetEnergyMeasurementsExport,
		EnergyMeasurementsAggregation,
		LineChart,
		StackedColumnChart,
		AssetEnergyForecastAccuracyMetrics
	},
	data() {
		return {
			loading: false,
			error: null,
			asset: null,
			selected_resolution: null,
			measurements: null,
			measurements_aggregation: null,
			variables_data: null,
			default_visible_variables: null,
			no_data_in_asset: null,
			show_data_export_tool: false,
			used_filters: null,
			asset_has_energy_forecast: null,
			asset_has_weather_measurements: null,

			energy_values_cache: {}
		};
	},
	async mounted() {
		const asset_id = this.$route.params.asset_id;
		this.asset = await AssetFinder.get_asset(asset_id);
	},
	methods: {
		async load_measurements(filter) {
			this.used_filters = null;
			if (filter.no_data) {
				this.no_data_in_asset = true;
				return;
			}

			this.loading = true;
			this.measurements = null;
			this.measurements_aggregation = null;
			this.asset_has_energy_forecast = false;

			try {
				const asset_id = this.$route.params.asset_id;
				this.selected_resolution = filter.resolution;
				this.used_filters = filter;

				switch (filter.resolution) {
					case "optimal":
						let promises = [];
						const selected_date_from = filter.date[0];
						const selected_date_to = filter.date[1];

						let energy_measurements, weather_measurements;

						//Get energy measurements
						let energy_measurements_promise = async () => {
							energy_measurements =
								await this.get_energy_measurements_optiomal_resolution(
									asset_id,
									selected_date_from,
									selected_date_to
								);
						};
						promises.push(energy_measurements_promise());

						//Get weather measurements
						if (this.asset_has_weather_measurements != false) {
							let weather_measurements_promise = async () => {
								weather_measurements =
									await AssetDayOptimalWeatherMeasurementsFinder.get_asset_optimal_weaher_measurements(
										asset_id,
										selected_date_from,
										selected_date_to,
										true
									);
								this.asset_has_weather_measurements = weather_measurements
									? true
									: false;
							};
							promises.push(weather_measurements_promise());
						}

						//Get energy forecast
						if (filter.asset_has_energy_forecast_data) {
							var energy_forecast;
							let energy_forecast_promise = async () => {
								energy_forecast =
									await AssetEnergyForecastFinder.get_asset_energy_forecast(
										asset_id,
										selected_date_from,
										selected_date_to
									);
							};
							promises.push(energy_forecast_promise());
						}

						//Execute all promises
						await Promise.all(promises);

						//Mix results
						this.measurements = [];
						this.default_visible_variables = [];
						const add_time_serie_to_chart_data = (time_serie) => {
							time_serie.forEach((item) => {
								let same_time_element = this.measurements.find((element) => {
									return moment(element.time).isSame(item.time, "minute");
								});

								if (same_time_element) {
									for (let variable in item) {
										if (variable == "time") continue;

										same_time_element[variable] = item[variable];
									}
								} else {
									this.measurements.push(item);
								}
							});
						};

						let chart_variables = {};
						if (energy_measurements) {
							const energy_chart_variables = [
								"power_production",
								"power_consumption",
								"power_self_consumption",
								"power_to_grid",
								"power_from_grid",
								"power_to_battery",
								"power_from_battery"
							];
							energy_chart_variables.forEach((item) => {
								chart_variables[item] = PvMeasuredVariables[item];
							});

							add_time_serie_to_chart_data(energy_measurements);

							this.default_visible_variables = [
								"power_consumption",
								"power_production"
							];
						}

						if (weather_measurements && weather_measurements.length > 0) {
							const weather_variables =
								WeatherMeasurementsVariablesUtil.get_variables();
							for (let variable in weather_variables) {
								chart_variables[variable] = weather_variables[variable];
							}

							add_time_serie_to_chart_data(weather_measurements);
						}

						if (energy_forecast && energy_forecast.length > 0) {
							const energy_chart_variables = [
								"energy_production_forecast",
								"energy_consumption_forecast"
							];
							energy_chart_variables.forEach((item) => {
								let variable_data = PvMeasuredVariables[item];

								//Set hourly energy forecast as power to fit in same y-axis as energy measurements
								if (
									energy_measurements &&
									(chart_variables.power_production ||
										chart_variables.power_consumption)
								) {
									variable_data.unit = "kW";
									variable_data.dashed_number = 3;
								}

								chart_variables[item] = variable_data;
							});

							if (chart_variables["energy_production_forecast"])
								this.default_visible_variables.push(
									"energy_production_forecast"
								);

							if (chart_variables["energy_consumption_forecast"])
								this.default_visible_variables.push(
									"energy_consumption_forecast"
								);

							add_time_serie_to_chart_data(energy_forecast);
							this.asset_has_energy_forecast = true;
						}

						this.variables_data = chart_variables;

						this.measurements_aggregation =
							await AssetDailyAggregatedEnergyFinder.get_asset_daily_aggregated_energy(
								asset_id,
								selected_date_from,
								selected_date_to
							);
						break;

					case "daily":
						const from = moment(filter.date).startOf("month").toDate();
						const to = moment(filter.date).endOf("month").toDate();

						this.measurements =
							await AssetDailyEnergyFinder.get_asset_daily_energy(
								asset_id,
								from,
								to
							);

						if (!this.measurements) {
							this.measurements = [];
							return;
						}

						this.default_visible_variables = [
							"energy_consumption",
							"energy_production"
						];

						this.variables_data = [];
						const daily_chart_variables = [
							{
								prop_name: "energy_production",
								group: "energy_production"
							},
							{
								prop_name: "energy_consumption",
								group: "energy_consumption"
							},
							{
								prop_name: "energy_self_consumption",
								group: "other"
							},
							{
								prop_name: "energy_to_grid",
								group: "other"
							},
							{
								prop_name: "energy_from_grid",
								group: "other"
							},
							{
								prop_name: "energy_to_battery",
								group: "other"
							},
							{
								prop_name: "energy_from_battery",
								group: "other"
							}
						];

						daily_chart_variables.forEach((item) => {
							const variable_data = PvMeasuredVariables[item.prop_name];
							this.variables_data.push({
								prop_name: item.prop_name,
								label: this.$t(variable_data.name),
								color: variable_data.color,
								group: item.group
							});
						});

						this.measurements_aggregation =
							await AssetDailyAggregatedEnergyFinder.get_asset_daily_aggregated_energy(
								asset_id,
								from,
								to
							);

						break;

					case "monthly":
						this.measurements =
							await AssetMonthlyEnergyFinder.get_asset_monthly_energy_by_date(
								asset_id,
								moment(filter.date).startOf("year"),
								moment(filter.date).endOf("year")
							);

						if (!this.measurements) {
							this.measurements = [];
							return;
						}

						this.default_visible_variables = [
							"energy_consumption",
							"energy_production"
						];

						this.variables_data = [];
						const monthly_chart_variables = [
							{
								prop_name: "energy_production",
								group: "energy_production"
							},
							{
								prop_name: "energy_consumption",
								group: "energy_consumption"
							},
							{
								prop_name: "energy_self_consumption",
								group: "other"
							},
							{
								prop_name: "energy_to_grid",
								group: "other"
							},
							{
								prop_name: "energy_from_grid",
								group: "other"
							},

							{
								prop_name: "energy_to_battery",
								group: "other"
							},
							{
								prop_name: "energy_from_battery",
								group: "other"
							}
						];

						if (this.measurements.length < 12) {
							var null_month_values = {};
							monthly_chart_variables.forEach(
								(item) => (null_month_values[item.prop_name] = null)
							);

							let processed_measurements = [];
							for (let x = 1; x <= 12; x++) {
								const month_measurements = this.measurements.find(
									(item) => Number(moment(item.time).format("M")) == x
								);

								if (month_measurements) {
									processed_measurements.push(month_measurements);
								} else {
									var value_to_push = Object.assign({}, null_month_values);
									value_to_push.time = moment(filter.date)
										.month(x - 1)
										.startOf("month")
										.toDate();
									processed_measurements.push(value_to_push);
								}
							}
							this.measurements = processed_measurements;
						}

						monthly_chart_variables.forEach((item) => {
							const variable_data = PvMeasuredVariables[item.prop_name];
							this.variables_data.push({
								prop_name: item.prop_name,
								label: this.$t(variable_data.name),
								color: variable_data.color,
								group: item.group
							});
						});

						this.measurements_aggregation = {
							energy_production: null,
							energy_consumption: null,
							energy_to_grid: null,
							energy_from_grid: null,
							energy_self_consumption: null,
							energy_to_battery: null,
							energy_from_battery: null
						};

						this.measurements.forEach((item) => {
							monthly_chart_variables.forEach((variable_data) => {
								const variable_name = variable_data.prop_name;

								if (this.$is_number(item[variable_name]))
									this.measurements_aggregation[variable_name] +=
										item[variable_name];
							});
						});
						break;

					case "yearly":
						this.default_visible_variables = [
							"energy_consumption",
							"energy_production"
						];

						this.variables_data = [];
						const yearly_chart_variables = [
							{
								prop_name: "energy_production",
								group: "energy_production"
							},
							{
								prop_name: "energy_consumption",
								group: "energy_consumption"
							},
							{
								prop_name: "energy_self_consumption",
								group: "other"
							},
							{
								prop_name: "energy_to_grid",
								group: "other"
							},
							{
								prop_name: "energy_from_grid",
								group: "other"
							},
							{
								prop_name: "energy_to_battery",
								group: "other"
							},
							{
								prop_name: "energy_from_battery",
								group: "other"
							}
						];
						yearly_chart_variables.forEach((item) => {
							const variable_data = PvMeasuredVariables[item.prop_name];
							this.variables_data.push({
								prop_name: item.prop_name,
								label: this.$t(variable_data.name),
								color: variable_data.color,
								group: item.group
							});
						});

						const asset_monthly_total_measurements =
							await AssetMonthlyEnergyFinder.get_asset_monthly_energy(asset_id);
						this.measurements = [];

						asset_monthly_total_measurements.forEach((item) => {
							const item_year = this.$format_date_to_year_number(item.time);

							let item_year_measurements = this.measurements.find(
								(element) => element.time == item_year
							);
							if (!item_year_measurements) {
								let year_measurements = {
									time: item_year
								};
								yearly_chart_variables.forEach((variable_data) => {
									if (this.$is_number(item[variable_data.prop_name])) {
										year_measurements[variable_data.prop_name] =
											item[variable_data.prop_name];
									}
								});
								this.measurements.push(year_measurements);
							} else {
								yearly_chart_variables.forEach((variable_data) => {
									if (this.$is_number(item[variable_data.prop_name])) {
										if (
											!this.$is_number(
												item_year_measurements[variable_data.prop_name]
											)
										)
											item_year_measurements[variable_data.prop_name] = 0;

										item_year_measurements[variable_data.prop_name] +=
											item[variable_data.prop_name];
									}
								});
							}
						});

						this.measurements_aggregation = {
							energy_production: null,
							energy_consumption: null,
							energy_to_grid: null,
							energy_from_grid: null,
							energy_self_consumption: null,
							energy_to_battery: null,
							energy_from_battery: null
						};
						this.measurements.forEach((item) => {
							yearly_chart_variables.forEach((variable_data) => {
								const variable_name = variable_data.prop_name;

								if (this.$is_number(item[variable_name]))
									this.measurements_aggregation[variable_name] +=
										item[variable_name];
							});

							item.time = moment().year(item.time).toDate();
						});
						break;
					default:
						break;
				}
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		async get_energy_measurements_optiomal_resolution(asset_id, from, to) {
			const days_diff = moment(to).diff(from, "days");
			const days_to_download = days_diff + 1;

			if (!this.energy_values_cache.energy_measurements_optiomal_resolution) {
				this.energy_values_cache.energy_measurements_optiomal_resolution = {
					optimal: {}
				};
			}

			var cache =
				this.energy_values_cache.energy_measurements_optiomal_resolution
					.optimal;
			var result = {};
			var promises = [];
			for (let index = 0; index < days_to_download; index++) {
				const day_to_request = moment(from).add(index, "days").toDate();
				const formatted_day_to_request =
					moment(day_to_request).format("YYYYMMDD");

				if (cache.hasOwnProperty(formatted_day_to_request))
					result[formatted_day_to_request] = cache[formatted_day_to_request];
				else {
					promises.push(async () => {
						const day_result =
							await AssetDayOptimalPowerFinder.get_asset_day_optimal_power(
								asset_id,
								day_to_request,
								true
							);

						if (day_result && day_result.length > 0) {
							result[formatted_day_to_request] = day_result;
							cache[formatted_day_to_request] = day_result;
						}
					});
				}
			}

			await this.$execute_promise_functions_in_batches(promises, 4);

			return Object.values(result).flat(2);
		}
	}
};
</script>

<style scoped>
#asset-energy-measurements-tool {
	background-color: rgba(0, 0, 0, 0.3);
	left: 0;
	top: 0;
	z-index: 1;
}
</style>
