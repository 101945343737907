import TicketFinderInterfaceClass from "../infrastructure/ticket-finder-interface.js";
import TicketValidator from "../domain/ticket-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = TicketValidator;
		this.backend_interface = new TicketFinderInterfaceClass();
	}

	async get_ticket(ticket_id) {
		try {
			let result = await this.backend_interface.get_ticket(ticket_id);

			return this.validator.validate(result);
		} catch (e) {
			throw new AppError("error_get_ticket", "general.errors.general_error", e);
		}
	}
}
