<template>
	<div class="display-flex gap-s">
		<button class="btn btn-primary" v-on:click="edit">
			<Icon icon="edit" />
		</button>

		<div v-if="downloading_pdf" class="flex-center-content">
			<Loader size="25" />
		</div>
		<button v-else class="btn btn-primary" v-on:click="export_report">
			<Icon icon="export" />
		</button>

		<div v-if="loading_send_report" class="flex-center-content">
			<Loader size="25" />
		</div>
		<button v-else class="btn btn-primary" v-on:click="share">
			<Icon icon="share" />
		</button>
	</div>
</template>

<script>
import Loader from "../../../../sub_components/Loader.vue";

export default {
	name: "ReportActionButtons",
	components: {
		Loader
	},
	props: {
		downloading_pdf: Boolean,
		loading_send_report: Boolean
	},
	methods: {
		edit() {
			this.$emit("edit");
		},
		export_report() {
			this.$emit("export");
		},
		share() {
			this.$emit("share");
		}
	}
};
</script>
