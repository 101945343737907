<template>
	<div>
		<div v-if="loading">
			<Loader size="20" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="asset_tickets">
			<div>
				<span class="font-bold">
					{{ $t("asset_reports.shared_components.tickets") }}
				</span>
			</div>
			<div>
				<Multiselect
					v-model="selected_tickets"
					:options="asset_tickets"
					:multiple="true"
					label="title"
					track_by="id"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "../../../../../sub_components/Loader.vue";
import Alert from "../../../../../sub_components/Alert.vue";
import Multiselect from "../../../../../sub_components/form_components/Multiselect.vue";

import TicketsFinderClass from "../../../../../../../../src/ticket/application/tickets-finder";
const TicketsFinder = new TicketsFinderClass();

export default {
	name: "ReportTicketsEdit",
	components: {
		Loader,
		Alert,
		Multiselect
	},
	props: {
		asset_id: String,
		date_from: Date,
		date_to: Date,
		value: Array
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_tickets: null,
			selected_tickets: this.value
		};
	},
	async mounted() {
		this.loading = true;

		try {
			if (this.value) this.selected_tickets = this.value;

			this.asset_tickets = await TicketsFinder.get_tickets(
				[this.asset_id],
				null,
				null,
				null,
				null,
				this.date_from,
				this.date_to
			);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		emit_imput_value() {
			this.$emit("input", this.selected_tickets);
		}
	},
	watch: {
		selected_tickets(new_value) {
			this.emit_imput_value();
		},
		value(new_value) {
			this.selected_tickets = new_value;
		}
	}
};
</script>
