import TicketsFinderInterfaceClass from "../infrastructure/tickets-finder-interface.js";
import TicketValidator from "../domain/ticket-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = TicketValidator;
		this.backend_interface = new TicketsFinderInterfaceClass();
	}

	async get_tickets(
		assets = null,
		created_by = null,
		assigned_to = null,
		status = null,
		faulty_equipments = null,
		creation_from = null,
		creation_to = null
	) {
		try {
			let result = await this.backend_interface.get_tickets(
				assets,
				created_by,
				assigned_to,
				status,
				faulty_equipments,
				creation_from,
				creation_to
			);

			const validated_result = result.map((item) =>
				this.validator.validate(item)
			);

			return validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_tickets",
				"general.errors.general_error",
				e
			);
		}
	}
}
