<template lang="html">
	<div>
		<div class="border-b font-bold">
			{{ $t("ticketing.settings.status_settings") }}
		</div>

		<div class="full-width relative">
			<div
				v-if="status_to_delete"
				id="remove-element"
				class="full-width full-height"
			>
				<div class="rounded-s overflow-hidden">
					<PageHeader :text="$t('general.delete')" />

					<div v-if="loading_delete" class="p-sm white-bg">
						<Loader size="30" />
					</div>

					<div v-else class="white-bg full-width overflow-y-scroll">
						<div class="p-sm">
							<div v-if="delete_error">
								<Alert :message="delete_error" />
							</div>

							<div>
								{{
									$t("ticketing.settings.remove_element_confirmation", {
										element_name: status_to_delete.name
									})
								}}
							</div>

							<div v-if="available_status_to_replace_with" class="m-t">
								<div class="">
									<span
										>{{ $t("ticketing.settings.replace_status_with") }}:</span
									>
								</div>
								<Multiselect
									:multiple="false"
									:options="available_status_to_replace_with"
									:searchable="true"
									:placeholder="
										$t('ticketing.settings.select_status_placeholder')
									"
									v-model="replace_status_with"
									label="name"
									track_by="id"
								/>
							</div>
						</div>

						<hr class="nomargin" />

						<div class="p-sm">
							<div class="right">
								<div
									class="btn btn-primary full-width"
									v-on:click="remove_status(status_to_delete)"
								>
									{{ $t("general.delete") }}
								</div>
							</div>
							<div class="right m-r">
								<div
									class="btn btn-danger full-width"
									v-on:click="status_to_delete = null"
								>
									{{ $t("general.cancel") }}
								</div>
							</div>
							<div class="clear"></div>
						</div>
					</div>
				</div>
			</div>

			<div id="content" class="m-t">
				<div v-if="loading">
					<Loader size="30" />
				</div>
				<div v-else>
					<div v-if="error">
						<Alert :message="error" />
					</div>
					<div v-if="success_message">
						<Alert type="success" :message="success_message" />
					</div>

					<div v-if="user_ticketing_status">
						<table class="full-width">
							<tr v-for="(status, status_id) in user_ticketing_status">
								<td class="p-t p-b">
									<input
										type="text"
										v-model="status.name"
										class="form-control"
									/>
								</td>
								<td class="p-t p-b">
									<ColorPicker v-model="status.color" />
								</td>
								<td class="button-width p-t p-b text-right">
									<div
										class="btn btn-primary full-width"
										v-on:click="update_status(status)"
									>
										{{ $t("general.save") }}
									</div>
								</td>
								<td class="button-width p-l">
									<div
										class="btn btn-danger full-width"
										v-on:click="status_to_delete = status"
									>
										{{ $t("general.delete") }}
									</div>
								</td>
							</tr>
							<tr>
								<td class="p-t p-b">
									<input
										type="text"
										v-model="new_status.name"
										class="form-control"
										:placeholder="$t('ticketing.settings.add_new_status')"
									/>
								</td>
								<td class="p-t p-b">
									<ColorPicker v-model="new_status.color" />
								</td>
								<td class="button-width p-t p-b text-right">
									<div
										class="btn btn-primary full-width"
										v-on:click="create_status(new_status)"
									>
										{{ $t("general.add_new") }}
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import ColorPicker from "@form_components/ColorPicker";
import PageHeader from "@sub_components/PageHeader";
import Multiselect from "@form_components/Multiselect";

import TicketStatusFinderClass from "../../../../../../src/ticket/application/ticket-status/ticket-status-finder.js";
import TicketServiceClass from "@services/user-services/ticket-service";

const TicketStatusFinder = new TicketStatusFinderClass();
const TicketService = new TicketServiceClass();

export default {
	name: "TicketingStatusSettings",
	components: {
		Loader,
		Alert,
		PageHeader,
		ColorPicker,
		Multiselect
	},
	data() {
		return {
			loading: null,
			error: null,
			success_message: false,
			user_ticketing_status: null,
			new_status: {},
			status_to_delete: null,
			loading_delete: false,
			delete_error: null,
			replace_status_with: null
		};
	},
	mounted() {
		this.load_user_ticketing_status();
	},
	methods: {
		async load_user_ticketing_status() {
			this.loading = true;
			this.error = null;
			try {
				var user_ticketing_status =
					await TicketStatusFinder.get_ticket_status();
				this.user_ticketing_status = JSON.parse(
					JSON.stringify(user_ticketing_status)
				);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		async update_status(status) {
			this.loading = true;
			this.error = null;
			this.success_message = null;

			try {
				await TicketService.update_ticketing_status(status);

				await this.load_user_ticketing_status();

				this.success_message = "general.update_successful";
				setTimeout(() => {
					this.success_message = false;
				}, 10000);

				this.load_user_ticketing_status();
			} catch (e) {
				this.error = e;
				this.loading = false;
			}
		},
		async create_status(new_status) {
			this.loading = true;
			this.error = null;

			try {
				await TicketService.create_ticketing_status(new_status);

				await this.load_user_ticketing_status();

				this.new_status = {};
				this.success_message = "general.created_successful";
				setTimeout(() => {
					this.success_message = false;
				}, 10000);
			} catch (e) {
				this.error = e;
				this.loading = false;
			}
		},
		async remove_status(status) {
			this.loading_delete = true;
			this.delete_error = null;
			this.success_message = null;

			try {
				await TicketService.remove_ticketing_status(
					status.id,
					this.replace_status_with
				);
				this.status_to_delete = null;
				this.replace_status_with = null;

				await this.load_user_ticketing_status();
			} catch (e) {
				this.delete_error = e;
				setTimeout(() => {
					this.delete_error = null;
				}, 8000);
			} finally {
				this.loading_delete = false;
			}
		}
	},
	computed: {
		available_status_to_replace_with() {
			if (!this.user_ticketing_status) return null;
			else if (!this.status_to_delete) return this.user_ticketing_status;
			else {
				let result = [];

				for (let index in this.user_ticketing_status) {
					const status = this.user_ticketing_status[index];

					if (status != this.status_to_delete) result.push(status);
				}

				return result;
			}
		}
	},
	watch: {
		status_to_delete(new_value, old_value) {
			if (!new_value) this.replace_status_with = null;
		}
	}
};
</script>

<style lang="css" scoped>
#content {
	width: 600px;
}
.button-width {
	width: 150px;
}
#remove-element {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
}
#remove-element > div {
	position: absolute;
	width: 400px;
	left: calc(50% - 200px);
	top: 40px;
}
</style>
