export default {
	power_consumption: {
		unit: "kW",
		is_percentage: false,
		name: "pv_main_panels.consumption",
		color: "var(--green1)"
	},
	energy_consumption: {
		unit: "kWh",
		is_percentage: false,
		name: "pv_main_panels.consumption",
		color: "var(--green1)"
	},
	energy_consumption_forecast: {
		unit: "kWh",
		is_percentage: false,
		name: "entities.asset_energy_forecast.energy_consumption_forecast",
		color: "#32a5608a"
	},
	power_production: {
		unit: "kW",
		is_percentage: false,
		name: "pv_main_panels.generation",
		color: "#FCB12A"
	},
	energy_production: {
		unit: "kWh",
		is_percentage: false,
		name: "pv_main_panels.generation",
		color: "#FCB12A"
	},
	energy_production_forecast: {
		unit: "kWh",
		is_percentage: false,
		name: "entities.asset_energy_forecast.energy_production_forecast",
		color: "#976a19"
	},
	power_from_grid: {
		unit: "kW",
		is_percentage: false,
		name: "pv_main_panels.from_grid",
		color: "#EE594F"
	},
	energy_from_grid: {
		unit: "kWh",
		is_percentage: false,
		name: "pv_main_panels.from_grid",
		color: "#EE594F"
	},
	power_to_grid: {
		unit: "kW",
		is_percentage: false,
		name: "pv_main_panels.to_grid",
		color: "var(--blue1)"
	},
	energy_to_grid: {
		unit: "kWh",
		is_percentage: false,
		name: "pv_main_panels.to_grid",
		color: "var(--blue1)"
	},
	power_from_battery: {
		unit: "kW",
		is_percentage: false,
		name: "pv_main_panels.from_battery",
		color: "#818898"
	},
	energy_from_battery: {
		unit: "kWh",
		is_percentage: false,
		name: "pv_main_panels.from_battery",
		color: "#818898"
	},
	power_to_battery: {
		unit: "kW",
		is_percentage: false,
		name: "pv_main_panels.to_battery",
		color: "#4A5060"
	},
	energy_to_battery: {
		unit: "kWh",
		is_percentage: false,
		name: "pv_main_panels.to_battery",
		color: "#4A5060"
	},
	power_self_consumption: {
		unit: "kW",
		is_percentage: false,
		name: "pv_main_panels.solar_consumption",
		shortName: "Solar consumption",
		color: "var(--blue2)"
	},
	energy_self_consumption: {
		unit: "kWh",
		is_percentage: false,
		name: "pv_main_panels.solar_consumption",
		shortName: "Solar consumption",
		color: "var(--blue2)"
	},
	rate_self_consumption: {
		is_percentage: true,
		name: "pv_main_panels.self_consumption",
		color: "#a9eff2"
	},
	rate_self_sufficiency: {
		is_percentage: true,
		name: "pv_main_panels.self_sufficiency",
		color: "#8bd9ae"
	}
};
