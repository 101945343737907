import Joi from "joi";
import Validator from "@validators/validator";

import FroniusLoginValidator from "./energy-providers-login-validators/fronius-login-validator";
import IngeteamLoginValidator from "./energy-providers-login-validators/ingeteam-login-validator";
import WibeeeLoginValidator from "./energy-providers-login-validators/wibeee-login-validator";
import HuaweiFusionsolarLoginValidator from "./energy-providers-login-validators/huawei-fusionsolar-login-validator";
import HuaweiNetecoLoginValidator from "./energy-providers-login-validators/huawei-neteco-login-validator";
import EnphaseLoginValidator from "./energy-providers-login-validators/enphase-login-validator";
import EnphaseV4LoginValidator from "./energy-providers-login-validators/enphase-v4-login-validator";
import DatadisLoginValidator from "./energy-providers-login-validators/datadis-login-validator";
import TwinmeterLoginValidator from "./energy-providers-login-validators/twinmeter-login-validator";
import VictronLoginValidator from "./energy-providers-login-validators/victron-login-validator";
import SungrowLoginValidator from "./energy-providers-login-validators/sungrow-login-validator";
import SolaredgeLoginValidator from "./energy-providers-login-validators/solaredge-login-validator";
import LacecalLoginValidator from "./energy-providers-login-validators/lacecal-login-validator";
import GrowattLoginValidator from "./energy-providers-login-validators/growatt-login-validator";
import SolisLoginValidator from "./energy-providers-login-validators/solis-login-validator";
import CanadianSolarLoginValidator from "./energy-providers-login-validators/canadian-solar-login-validator.js";
import KostalLoginValidator from "./energy-providers-login-validators/kostal-login-validator.js";
import GoodweLoginValidator from "./energy-providers-login-validators/goodwe-login-validator.js";
import RenesysLoginValidator from "./energy-providers-login-validators/renesys-login-validator.js";

export default (provider_name) => {
	var auth_validators = {
		fronius: FroniusLoginValidator,
		sma: null,
		enphase: EnphaseLoginValidator,
		"enphase-v4": EnphaseV4LoginValidator,
		ingeteam: IngeteamLoginValidator,
		wibeee: WibeeeLoginValidator,
		"huawei-fusionsolar": HuaweiFusionsolarLoginValidator,
		"huawei-neteco": HuaweiNetecoLoginValidator,
		datadis: DatadisLoginValidator,
		twinmeter: TwinmeterLoginValidator,
		victron: VictronLoginValidator,
		sungrow: SungrowLoginValidator,
		vmc: null,
		solaredge: SolaredgeLoginValidator,
		lacecal: LacecalLoginValidator,
		growatt: GrowattLoginValidator,
		"growatt-device": GrowattLoginValidator,
		solis: SolisLoginValidator,
		"canadian-solar": CanadianSolarLoginValidator,
		kostal: KostalLoginValidator,
		goodwe: GoodweLoginValidator,
		renesys: RenesysLoginValidator
	};

	if (!auth_validators[provider_name]) return null;

	const schema = Joi.object({
		auth: auth_validators[provider_name],
		name: Joi.string()
	});

	return new Validator(schema, "entities.energy_provider_auth.");
};
