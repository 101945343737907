<template>
	<div class="m-t display-flex gap-s grey1-color">
		<div
			class="display-grid gap-s"
			:style="{
				'grid-template-columns':
					'repeat(' + (filters_type == 'systems' ? 4 : 3) + ', 280px)'
			}"
		>
			<div v-if="filters_type == 'systems'" class="display-grid gap-xs">
				<div class="font-700">
					{{ $t("entities.energy_system.cups") }}
				</div>
				<div>
					<TextInput v-model="filter.cups" />
				</div>
			</div>

			<div class="display-grid gap-xs">
				<div class="font-700">
					{{ $t("general.status") }}
				</div>
				<div>
					<Multiselect
						v-model="filter.status_provided"
						:multiple="true"
						:options="
							status_options.map((item) => {
								return {
									label: $t(
										'entities.assets_group_summary.systems_status.' + item
									),
									value: item
								};
							})
						"
						:close_on_select="false"
					/>
				</div>
			</div>

			<div class="display-grid gap-xs">
				<div class="font-700">
					{{ $t("entities.energy_system.system_type") }}
				</div>
				<div>
					<Multiselect
						v-model="system_type_value"
						:multiple="true"
						:options="system_type_options"
						:close_on_select="false"
					/>
				</div>
			</div>

			<div class="display-grid gap-xs">
				<div class="font-700">
					{{ $t("entities.energy_system.provider_data.provider_name") }}
				</div>
				<div>
					<EnergyDataProvidersMultiselect
						v-model="filter.provider_names"
						:multiple="true"
					/>
				</div>
			</div>
		</div>

		<div class="flex-vertical-bottom gap-s">
			<button class="btn btn-primary-inverse" v-on:click="reset_filter">
				{{ $t("general.clean") }}
			</button>
			<button class="btn btn-primary" v-on:click="emit_filter">
				{{ $tc("general.filter", 1) }}
			</button>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";
import Multiselect from "@form_components/Multiselect.vue";
import EnergyDataProvidersMultiselect from "@form_components/selectors/EnergyDataProvidersMultiselect.vue";

export default {
	name: "AssetsGroupFilters",
	props: {
		filters_type: String //assets | systems
	},
	components: {
		TextInput,
		Multiselect,
		EnergyDataProvidersMultiselect
	},
	data() {
		return {
			filter: {
				status_provided: null,
				systems_info: null,
				provider_names: null
			},
			system_type_value: null,
			status_options: ["ok", "offline", "faulty", "unavailable", "unsupported"]
		};
	},
	methods: {
		reset_filter() {
			this.filter = {
				status_provided: null,
				systems_info: null,
				provider_names: null
			};
			this.system_type_value = null;
		},
		emit_filter() {
			let any_active_filter = false;
			for (let index in this.filter) {
				if (
					this.filter[index] &&
					Array.isArray(this.filter[index]) &&
					this.filter[index].length == 0
				)
					this.filter[index] = null;

				if (this.filter[index]) any_active_filter = true;
			}

			this.$emit(
				"filter",
				any_active_filter ? Object.assign({}, this.filter) : null
			);
		}
	},
	computed: {
		system_type_options() {
			return [
				{
					label: this.$t(
						"entities.assets_group_asset_summary.asset_systems_info.has_battery"
					),
					value: "has_battery"
				},
				{
					label: this.$t("pv_main_panels.generation"),
					value: "has_generation"
				},
				{
					label: this.$t("pv_main_panels.consumption"),
					value: "has_consumption"
				},
				{
					label: this.$t(
						"entities.assets_group_asset_summary.asset_systems_info.has_grid_interaction"
					),
					value: "has_grid_interaction"
				}
			];
		}
	},
	watch: {
		filters_type() {
			this.reset_filter();
		},
		system_type_value(new_value) {
			if (!new_value || new_value.length == 0) {
				this.filter.systems_info = null;
				return;
			}

			let formatted_value = [];

			new_value.forEach((value) => {
				switch (value) {
					case "has_generation":
						formatted_value.push("has_pv_generation");
						formatted_value.push("has_wind_power_generation");
						break;

					default:
						formatted_value.push(value);
						break;
				}
			});

			this.filter.systems_info = formatted_value;
		}
	}
};
</script>
