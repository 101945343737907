<template>
	<div class="display-flex flex-row-wrap gap-m">
		<div style="width: 100%; max-width: 500px">
			<Alert message="Danger alert" />
		</div>
		<div style="width: 100%; max-width: 500px">
			<Alert message="Warning alert" type="warning" />
		</div>
		<div style="width: 100%; max-width: 500px">
			<Alert message="Info alert" type="info" />
		</div>
		<div style="width: 100%; max-width: 500px">
			<Alert message="Success alert" type="success" />
		</div>
	</div>
</template>

<script>
import Alert from "../../../sub_components/Alert.vue";

export default {
	name: "AlertExample",
	components: { Alert }
};
</script>
