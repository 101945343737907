<template>
	<div class="full-width full-height relative">
		<div class="absolute full-width full-height">
			<div class="flex-auto-height-content full-width full-height">
				<div class="header">
					<div class="display-flex gap-m p-sm">
						<div>
							<span>{{ $t("entities.energy_system.generation_type") }}: </span>
						</div>

						<div class="display-flex gap-s">
							<div>
								<span>
									{{
										$t(
											"entities.energy_system.generation_type_options." +
												cloned_energy_system.generation_type
										)
									}}
								</span>
							</div>
							<div>
								<Icon
									size="20"
									:icon="
										cloned_energy_system.generation_type == 'solar_pv'
											? 'solar-panel'
											: 'wind-turbine'
									"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="content relative">
					<div class="absolute full-width full-height">
						<EnergySystemEditPvData
							v-if="cloned_energy_system.generation_type == 'solar_pv'"
							:energy_system="cloned_energy_system"
							v-on:energy_system_updated="energy_system_updated"
						/>
						<EnergySystemEditWindPowerData
							v-else-if="cloned_energy_system.generation_type == 'wind_power'"
							:energy_system="cloned_energy_system"
							v-on:energy_system_updated="energy_system_updated"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EnergySystemEditPvData from "./pv-data/EnergySystemEditPvData.vue";
import EnergySystemEditWindPowerData from "./EnergySystemEditWindPowerData.vue";

export default {
	name: "EnergySystemEditGenerationData",
	components: {
		EnergySystemEditPvData,
		EnergySystemEditWindPowerData
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			cloned_energy_system: Object.assign({}, this.energy_system)
		};
	},
	methods: {
		energy_system_updated(energy_system) {
			this.$emit("energy_system_updated", energy_system);
			this.cloned_energy_system = Object.assign({}, energy_system);
		}
	}
};
</script>
