<template>
	<div class="p-xxs full-width full-height">
		<div class="flex-auto-full-height-content rounded-xs overflow-hidden">
			<PageHeader
				class="header"
				:text="
					header
						? $t('pv_main_panels.tab_header', {
								plant_name: header
						  })
						: null
				"
				close_name="app"
				:close_query="{ asset_id: asset ? asset.id : null }"
			/>
			<div v-if="loading" class="content flex-center-content white-bg">
				<Loader />
			</div>
			<div v-else class="content grey3-bg">
				<div class="flex-auto-full-height-content full-width full-height">
					<div class="header p-b">
						<Header v-if="asset" :asset="asset" />
					</div>
					<div class="content relative">
						<General v-if="$route.name == 'asset-main-panel'" :asset="asset" />
						<router-view v-else :asset="asset" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";

import Header from "./header/LocationEnergyDataMainPanelHeader.vue";
import General from "./general/AssetEnergyMainPanelGeneral.vue";

import AssetsFinderClass from "@src/asset/application/assets-finder";
import AssetEnergyDataAvailableDateRangeFinderClass from "../../../../../../src/asset-energy-data/application/asset-energy-data-available-date-range-finder.js";

const AssetsFinder = new AssetsFinderClass();
const AssetEnergyDataAvailableDateRangeFinder =
	new AssetEnergyDataAvailableDateRangeFinderClass();

export default {
	name: "AssetEnergyDataMainPanel",
	components: {
		PageHeader,
		Loader,
		Header,
		General
	},
	data() {
		return {
			loading: true,
			asset: null,
			error: null
		};
	},
	computed: {
		header() {
			return this.asset
				? this.asset.name +
						(this.asset.custom_id ? " - " + this.asset.custom_id : "")
				: null;
		}
	},
	mounted() {
		this.load_asset_data();
	},
	methods: {
		async load_asset_data() {
			this.loading = true;
			const asset_id = this.$route.params.asset_id;

			try {
				this.asset = null;
				var asset_energy_data_available_date_range;

				const get_asset = async () => {
					this.asset = await AssetsFinder.get_asset_by_id(asset_id);
				};

				const get_asset_energy_data_available_date_range = async () => {
					asset_energy_data_available_date_range =
						await AssetEnergyDataAvailableDateRangeFinder.get_asset_energy_data_available_date_range(
							asset_id
						);
				};

				await Promise.all([
					get_asset(),
					get_asset_energy_data_available_date_range()
				]);

				const asset_has_energy_forecast =
					asset_energy_data_available_date_range?.energy_forecast?.hourly;

				if (asset_has_energy_forecast) {
					//Route name is changed for not to load AssetEnergyMainPanelGeneral component
					this.$route.name = "asset-main-panel-measurements";
					this.$router.push({
						name: "asset-main-panel-measurements",
						params: { asset_id }
					});
				}
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#om_content {
	height: calc(100% - 35px);
}
</style>
