import Joi from "joi";
import Validator from "@validators/validator";

import EnergyDataProviderSlugValidator from "@src/energy-provider-auth/domain/energy-data-provider-slug-validator";
import PowerMeasurementValidator from "@src/asset-energy-data/domain/power-measurement-validator.js";

const schema = Joi.object({
	asset_id: Joi.string().required(),
	status_provided: Joi.string().valid(
		"ok",
		"offline",
		"faulty",
		"unavailable",
		"unsupported"
	),
	provider_names: Joi.array().items(EnergyDataProviderSlugValidator.schema),
	latest_measurements: Joi.object({
		today_energy_production: Joi.number().allow(null),
		latest_power_measurements: PowerMeasurementValidator.schema.allow(null)
	}),
	pv_data: Joi.object({
		pr: Joi.number(),
		peak_power: Joi.number(),
		nominal_power: Joi.number()
	}),
	wind_power_data: Joi.object({
		pr: Joi.number(),
		peak_power: Joi.number(),
		nominal_power: Joi.number()
	}),
	asset_systems_info: Joi.object({
		systems_count: Joi.number(),
		has_battery: Joi.boolean(),
		has_pv_generation: Joi.boolean(),
		has_wind_power_generation: Joi.boolean(),
		has_consumption: Joi.boolean(),
		has_grid_interaction: Joi.boolean()
	})
});
export default new Validator(schema);
