import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_devices_by_system_id(energy_system_id) {
		const url = "/api/energy-data/systems/" + energy_system_id + "/devices";
		const backend_energy_devices = await this.get(url);

		return BackendObjectConverter(backend_energy_devices, {
			date: {
				formatter: (value) => new Date(value)
			}
		});
	}
}
