import Joi from "joi";
import Validator from "@validators/validator";

import WindTurbineModelOptions from "./entities/wind-turbine-model-options.json";

const wind_power_data_schema = Joi.object({
	peak_power: Joi.number().min(1).required(),
	nominal_power: Joi.number().min(1),
	wind_turbines: Joi.number().min(1).required(),
	wind_turbine_model: Joi.string()
		.valid(...WindTurbineModelOptions)
		.required(),
	wind_turbine_nominal_power: Joi.number().min(0).required().required(),
	hub_height: Joi.number().min(1).required(),
	rotor_diameter: Joi.number().min(1).required(),
	losses: Joi.number().min(0).max(100)
});
export default new Validator(
	wind_power_data_schema,
	"entities.energy_system.wind_power_data."
);
