<template>
	<div class="display-flex flex-row-wrap gap-xl">
		<div class="display-flex gap-s">
			<div>Default tooltip</div>
			<div>
				<Tooltip tooltip_text="Tooltop text" position="right" />
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Single line tooltip text</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="right"
					:single_line_tooltip_text="true"
				/>
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Light theme</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="right"
					:single_line_tooltip_text="true"
					theme="light"
				/>
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Custom tooltip width</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="right"
					:width_in_px="250"
				/>
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Top tooltip</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="top"
					:single_line_tooltip_text="true"
				/>
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Left tooltip</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="left"
					:single_line_tooltip_text="true"
				/>
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Bottom tooltip</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="bottom"
					:single_line_tooltip_text="true"
				/>
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Right tooltip</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="right"
					:single_line_tooltip_text="true"
				/>
			</div>
		</div>

		<div class="display-flex gap-s">
			<div>Bottom right tooltip</div>
			<div>
				<Tooltip
					tooltip_text="Tooltop text"
					position="bottom-right"
					:single_line_tooltip_text="true"
				/>
			</div>
		</div>

		<div class="flex-vertical-center gap-s">
			<div>Custom tooltip</div>
			<div>
				<Tooltip position="top">
					<template #content>
						<button class="btn btn-primary">
							<Icon icon="sun" />
						</button>
					</template>

					<template #tooltip-content>
						<div class="p-sm">
							<Loader size="25" />
						</div>
					</template>
				</Tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import Tooltip from "../../../sub_components/Tooltip.vue";
import Loader from "../../../sub_components/Loader.vue";

export default {
	name: "TooltipExample",
	components: { Tooltip, Loader }
};
</script>
