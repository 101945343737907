import BackendTicketInterfaceClass from "@interfaces/backend-interfaces/backend-ticket-interface";
import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import TicketStatusStoreUtil from "../../../src/ticket/application/ticket-status/ticket-status-store-util";

import UserServiceClass from "@services/user-services/user-service";
import UserFinderClass from "@src/user/application/user-finder.js";
import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import TicketValidator from "../../../src/ticket/domain/ticket-validator";
import FaultyEquipmentValidator from "@validators/ticketing-validators/faulty-equipment-validator";
import PaidByValidator from "@validators/ticketing-validators/paid-by-validator";
import TicketStatusValidator from "../../../src/ticket/domain/ticket-status-validator";
import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.store_util = TicketStatusStoreUtil;
		this.store_interface = new StoreInterfaceClass();
		this.backend_ticket_interface = new BackendTicketInterfaceClass();

		this.user_service = new UserServiceClass();
		this.user_finder = new UserFinderClass();
		this.owned_users_finder = new OwnedUsersFinderClass();

		this.ticket_validator = TicketValidator;
		this.faulty_equipment_validator = FaultyEquipmentValidator;
		this.paid_by_validator = PaidByValidator;
		this.ticket_status_validator = TicketStatusValidator;
	}

	async create_ticket(ticket) {
		const validated_input_ticket = this.ticket_validator.validate(ticket);

		try {
			const interface_created_ticket =
				await this.backend_ticket_interface.create_ticket(
					validated_input_ticket
				);
			const validated_created_ticket = this.ticket_validator.validate(
				interface_created_ticket
			);
			return validated_created_ticket;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_create_ticket",
				"general.errors.general_error",
				e
			);
		}
	}

	async update_ticket(ticket) {
		const validated_input_ticket = this.ticket_validator.validate(ticket);

		try {
			const interface_updated_ticket =
				await this.backend_ticket_interface.update_ticket(
					validated_input_ticket
				);
			const validated_updated_ticket = this.ticket_validator.validate(
				interface_updated_ticket
			);
			return validated_updated_ticket;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_update_ticket",
				"general.errors.general_error",
				e
			);
		}
	}

	async close_ticket(ticket_id) {
		try {
			const interface_updated_ticket =
				await this.backend_ticket_interface.close_ticket(ticket_id);

			const validated_updated_ticket = this.ticket_validator.validate(
				interface_updated_ticket
			);
			return validated_updated_ticket;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_close_ticket",
				"general.errors.general_error",
				e
			);
		}
	}

	async remove_ticket(ticket_id) {
		try {
			return await this.backend_ticket_interface.remove_ticket(ticket_id);
		} catch (e) {
			throw new AppError(
				"ticket_service_error_remove_ticket",
				"general.errors.general_error",
				e
			);
		}
	}

	async create_ticketing_status(status) {
		const validated_status = this.ticket_status_validator.validate(status);
		try {
			const new_status =
				await this.backend_ticket_interface.create_ticketing_status(
					validated_status
				);
			const validated_new_status =
				this.ticket_status_validator.validate(new_status);

			await this.store_util.add_or_update_item(validated_new_status);
			return validated_new_status;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_create_ticketing_status",
				"general.errors.general_error",
				e
			);
		}
	}

	async update_ticketing_status(status) {
		const validated_status = this.ticket_status_validator.validate(status);
		try {
			const updated_status =
				await this.backend_ticket_interface.update_ticketing_status(
					validated_status
				);
			const validated_updated_status =
				this.ticket_status_validator.validate(updated_status);

			await this.store_util.add_or_update_item(validated_updated_status);
			return validated_updated_status;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_update_ticketing_status",
				"general.errors.general_error",
				e
			);
		}
	}

	async remove_ticketing_status(status_id, replacement_status_id = null) {
		try {
			const result =
				await this.backend_ticket_interface.remove_ticketing_status(
					status_id,
					replacement_status_id
				);

			await this.store_util.remove_item(status_id);
			return result;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_remove_ticketing_status",
				"general.errors.general_error",
				e
			);
		}
	}
	// --- END ticketing status --- //

	// --- START faulty equipments --- //
	async get_ticketing_faulty_equipments(force_download = null) {
		const entity = "ticket_equipments";
		let equipments = this.store_interface.get(entity);
		if (!equipments || force_download) {
			try {
				equipments =
					await this.backend_ticket_interface.get_ticketing_faulty_equipments();
				this.store_interface.set(
					entity,
					equipments,
					moment().add(1, "day").toDate()
				);
			} catch (e) {
				throw new AppError(
					"ticket_service_error_get_ticketing_faulty_equipments",
					"general.errors.general_error",
					e
				);
			}
		}
		return equipments;
	}

	async create_ticketing_faulty_equipment(faulty_equipment) {
		const validated_faulty_equipment =
			this.faulty_equipment_validator.validate(faulty_equipment);
		try {
			const new_faulty_equipment =
				await this.backend_ticket_interface.create_ticketing_faulty_equipment(
					validated_faulty_equipment
				);
			const validated_new_faulty_equipment =
				this.faulty_equipment_validator.validate(new_faulty_equipment);
			await this.get_ticketing_faulty_equipments(true);
			return validated_new_faulty_equipment;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_create_ticketing_faulty_equipment",
				"general.errors.general_error",
				e
			);
		}
	}

	async update_ticketing_faulty_equipment(faulty_equipment) {
		const validated_faulty_equipment =
			this.faulty_equipment_validator.validate(faulty_equipment);
		try {
			const updated_faulty_equipment =
				await this.backend_ticket_interface.update_ticketing_faulty_equipment(
					validated_faulty_equipment
				);
			const validated_updated_faulty_equipment =
				this.faulty_equipment_validator.validate(updated_faulty_equipment);

			await this.get_ticketing_faulty_equipments(true);
			return validated_updated_faulty_equipment;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_update_ticketing_faulty_equipment",
				"general.errors.general_error",
				e
			);
		}
	}

	async remove_faulty_equipment(
		faulty_equipment_id,
		replacement_faulty_equipment_id = null
	) {
		try {
			const result =
				await this.backend_ticket_interface.remove_faulty_equipment(
					faulty_equipment_id,
					replacement_faulty_equipment_id
				);

			await this.get_ticketing_faulty_equipments(true);

			return result;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_remove_faulty_equipment",
				"general.errors.general_error",
				e
			);
		}
	}
	// --- END faulty equipments --- //

	// --- START paid by --- //
	async get_ticketing_paid_by(force_download = null) {
		const entity = "ticket_paid_by";
		let paid_by = this.store_interface.get(entity);
		if (!paid_by || force_download) {
			try {
				paid_by = await this.backend_ticket_interface.get_ticketing_paid_by();
				this.store_interface.set(
					entity,
					paid_by,
					moment().add(1, "day").toDate()
				);
			} catch (e) {
				throw new AppError(
					"ticket_service_error_get_ticketing_paid_by",
					"general.errors.general_error",
					e
				);
			}
		}
		return paid_by;
	}

	async create_ticketing_paid_by(paid_by) {
		const validated_paid_by = this.paid_by_validator.validate(paid_by);
		try {
			const new_paid_by =
				await this.backend_ticket_interface.create_ticketing_paid_by(
					validated_paid_by
				);
			const validated_new_paid_by =
				this.paid_by_validator.validate(new_paid_by);

			await this.get_ticketing_paid_by(true);
			return validated_new_paid_by;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_create_ticketing_paid_by",
				"general.errors.general_error",
				e
			);
		}
	}

	async update_ticketing_paid_by(paid_by) {
		const validated_paid_by = this.paid_by_validator.validate(paid_by);
		try {
			const updated_paid_by =
				await this.backend_ticket_interface.update_ticketing_paid_by(
					validated_paid_by
				);
			const validated_updated_paid_by =
				this.paid_by_validator.validate(updated_paid_by);

			await this.get_ticketing_paid_by(true);
			return validated_updated_paid_by;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_update_ticketing_paid_by",
				"general.errors.general_error",
				e
			);
		}
	}

	async remove_ticketing_paid_by(paid_by_id, replacement_paid_by_id = null) {
		try {
			const result =
				await this.backend_ticket_interface.remove_ticketing_paid_by(
					paid_by_id,
					replacement_paid_by_id
				);

			await this.get_ticketing_paid_by(true);

			return result;
		} catch (e) {
			throw new AppError(
				"ticket_service_error_remove_ticketing_paid_by",
				"general.errors.general_error",
				e
			);
		}
	}
	// --- END paid by --- //

	/* Ticketing preferences */
	async save_ticketing_settings(ticketing_settings) {
		try {
			return this.backend_ticket_interface.save_ticketing_settings(
				ticketing_settings
			);
		} catch (e) {
			throw new AppError(
				"ticket_service_error_save_ticketing_settings",
				"general.errors.general_error",
				e
			);
		}
	}
	/* End ticketing preferences*/

	async get_allowed_assigned_to(location_id = null) {
		const current_user = await this.user_finder.get_user();
		const is_current_user_a_supervisor =
			this.user_service.user_has_role("supervisor");

		let result = {};

		if (is_current_user_a_supervisor) {
			let maintainers;
			if (location_id) {
				maintainers =
					await this.owned_users_finder.get_owned_users_by_allowed_asset_id(
						"maintainer",
						location_id
					);
			} else {
				maintainers = await this.owned_users_finder.get_owned_users_by_role(
					"maintainer"
				);
			}
			maintainers = Object.assign({}, maintainers);

			result = maintainers;
		}

		result[current_user.id] = current_user;

		return result;
	}
}
