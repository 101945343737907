<template lang="html">
	<div class="p-sm white-bg rounded-xs">
		<div class="display-flex-space-between">
			<div class="font-bold flex-center-content">
				<router-link
					v-for="(link, index) in menu"
					v-bind:key="'om_menu_' + index"
					:to="{
						name: link.name,
						params: { asset_id: asset.id }
					}"
				>
					<div
						class="p-l p-r"
						:class="$route.name == link.name ? 'primary-color' : 'grey2-color'"
					>
						<span>{{ $t(link.label) }}</span>
					</div>
				</router-link>
			</div>

			<div>
				<AssetShortcuts :asset="asset" />
			</div>
		</div>
	</div>
</template>

<script>
import AssetShortcuts from "@sub_components/location-components/AssetShortcuts";

export default {
	name: "LocationEnergyDataMainPanelHeader",
	components: {
		AssetShortcuts
	},
	props: {
		asset: Object
	},
	computed: {
		menu() {
			let menu = [
				{
					name: "asset-main-panel",
					label: "general.general"
				}
			];

			menu.push({
				name: "asset-main-panel-measurements",
				label: "pv_main_panels.measurements.label"
			});

			menu.push({
				name: "asset-main-panel-savings",
				label: "pv_main_panels.savings.label"
			});

			//Temporally hidden
			/*menu.push({
				name: "asset-main-panel-weather",
				label: "general.weather"
			});*/

			return menu;
		}
	}
};
</script>

<style lang="css" scoped></style>
