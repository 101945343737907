import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";

import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter.js";

function _convert_backend_provider_name(backend_provider_name) {
	return BackendEntityConverter.convert_backend_value_to_local(
		"energy_measurement_provider_names",
		backend_provider_name
	);
}

export default (backend_energy_system) => {
	return BackendObjectConverter(backend_energy_system, {
		creationDate: {
			formatter: (value) => new Date(value)
		},
		commissioningDate: {
			formatter: (value) => new Date(value)
		},
		providerName: {
			formatter: (value) => {
				return _convert_backend_provider_name(value);
			}
		},
		authId: {
			local_name: "energy_provider_auth_id"
		},
		customPrices: {
			local_name: "custom_price_types"
		},
		rateContributedGeneration: {
			formatter: (value) => {
				return isNaN(value) ? 0 : value * 100;
			}
		},
		rateContributedConsumption: {
			formatter: (value) => {
				return isNaN(value) ? 0 : value * 100;
			}
		}
	});
};
