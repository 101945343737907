<template>
	<div class="display-flex-vertical-space-between gap-m">
		<div class="display-grid gap-s">
			<div>Text inputs</div>
			<div class="display-flex flex-row-wrap gap-s">
				<div>
					<TextInput placeholder="Default text input" />
				</div>

				<div>
					<TextInput placeholder="With clean button" :clean_button="true" />
				</div>

				<div>
					<TextInput placeholder="Disabled" :disabled="true" />
				</div>

				<div>
					<TextInput placeholder="Password" :is_password="true" />
				</div>
			</div>
			<div class="display-flex flex-row-wrap gap-s">
				<div>
					<TextInput placeholder="Textarea" :height_in_px="120" />
				</div>
			</div>
		</div>

		<div>
			<div>Number inputs</div>
			<div class="display-flex flex-row-wrap gap-s">
				<div>
					<NumberInput
						v-model="number_input_1"
						placeholder="Default number input"
					/>
				</div>

				<div>
					<NumberInput placeholder="Disabled number input" :disabled="true" />
				</div>

				<div>
					<NumberInput placeholder="With error" :has_error="true" />
				</div>

				<div>
					<NumberInput placeholder="With unit" unit="kW" />
				</div>

				<div>
					<NumberInput
						v-model="number_input_2"
						placeholder="min 0 & max 100"
						:min="0"
						:max="100"
					/>
				</div>
			</div>
		</div>

		<div>
			<div id="multiselect-examples" class="display-flex flex-row-wrap gap-s">
				<div class="display-flex-vertical-space-between gap-xs">
					<div>Single select</div>
					<div>
						<Multiselect
							v-model="single_select_value"
							:options="options"
							:multiple="false"
						/>
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Multiselect</div>
					<div>
						<Multiselect
							v-model="multiselect_value"
							:options="options"
							:multiple="true"
						/>
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Disabled</div>
					<div>
						<Multiselect
							v-model="disabled_value"
							:options="options"
							:multiple="false"
							:disabled="true"
						/>
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Allow add new options</div>
					<div>
						<Multiselect
							v-model="add_new_options_value"
							:options="options"
							:multiple="true"
							:allow_new_options="true"
						/>
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Allow select all</div>
					<div>
						<Multiselect
							v-model="allow_select_all_value"
							:options="options"
							:multiple="true"
							:allow_select_all="true"
						/>
					</div>
				</div>
			</div>
		</div>

		<div>
			<div id="multiselect-examples" class="display-flex flex-row-wrap gap-s">
				<div class="display-flex-vertical-space-between gap-xs">
					<div>Standard daily datepicker</div>
					<div>
						<Datepicker v-model="datepicker_value_1" />
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Daily range datepicker - Max 5 days</div>
					<div>
						<Datepicker
							v-model="datepicker_value_2"
							:is_range="true"
							:max_range_days="5"
						/>
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Allow from - Allow to</div>
					<div>
						<Datepicker
							v-model="datepicker_value_3"
							:allow_from="datepicker_value_3_allow_from"
							:allow_to="datepicker_value_3_allow_to"
						/>
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Monthly datepicker</div>
					<div>
						<Datepicker v-model="datepicker_value_4" min_view="month" />
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Monthly range datepicker</div>
					<div>
						<Datepicker
							v-model="datepicker_value_5"
							:is_range="true"
							min_view="month"
						/>
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Yearly datepicker</div>
					<div>
						<Datepicker v-model="datepicker_value_6" min_view="year" />
					</div>
				</div>

				<div class="display-flex-vertical-space-between gap-xs">
					<div>Yearly range datepicker</div>
					<div>
						<Datepicker
							v-model="datepicker_value_7"
							:is_range="true"
							min_view="year"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from "../../../sub_components/form_components/TextInput.vue";
import NumberInput from "../../../sub_components/form_components/NumberInput.vue";
import Multiselect from "../../../sub_components/form_components/Multiselect.vue";
import Datepicker from "../../../sub_components/form_components/Datepicker.vue";

import moment from "moment";

export default {
	name: "FormInputExample",
	components: {
		TextInput,
		NumberInput,
		Multiselect,
		Datepicker
	},
	data() {
		return {
			number_input_1: null,
			number_input_2: null,

			single_select_value: null,
			multiselect_value: null,
			disabled_value: null,
			add_new_options_value: null,
			allow_select_all_value: null,
			options: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],

			datepicker_value_1: new Date(),
			datepicker_value_2: [moment().subtract(4, "days").toDate(), new Date()],
			datepicker_value_3: new Date(),
			datepicker_value_3_allow_from: moment().subtract(4, "days").toDate(),
			datepicker_value_3_allow_to: moment().add(4, "days").toDate(),
			datepicker_value_5: [moment().subtract(3, "months").toDate(), new Date()],
			datepicker_value_4: new Date(),
			datepicker_value_6: new Date(),
			datepicker_value_7: [moment().subtract(3, "years").toDate(), new Date()]
		};
	}
};
</script>

<style scoped>
#multiselect-examples > div {
	width: 300px;
}
</style>
