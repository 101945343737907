import es from "./translations/es";
import en from "./translations/en";
import cat from "./translations/cat";
import it from "./translations/it";
export default {
	en,
	es,
	cat,
	it
};
