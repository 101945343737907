const Joi = require("joi");
import Validator from "@validators/validator";

const estimated_production_schema = Joi.object({
	report_type: Joi.string().valid("data_download").required(),
	emails: Joi.array()
		.min(1)
		.items(Joi.string().email({ tlds: { allow: false } }))
		.required(),
	assets_ids: Joi.array().min(1).items(Joi.string()).required(),
	date_from: Joi.date().required(),
	date_to: Joi.date().required()
});

export default new Validator(
	estimated_production_schema,
	"entities.asset_reports_sender."
);
