<template lang="html">
	<div class="full-width full-height">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else-if="devices" class="full-width full-height">
			<div
				id="selected-device"
				class="flex-center-content"
				v-if="selected_device"
			>
				<EnergyDeviceInfo
					:energy_device="selected_device"
					v-on:close="selected_device = null"
				/>
			</div>

			<div class="flex-auto-full-height-content p-sm">
				<div class="header m-b p-b-xxs border-b font-bold">
					<div class="display-flex-space-between">
						<div class="flex-center-content">
							<span>
								{{ $t("user_settings.energy_systems_manager.devices") }}
							</span>
						</div>
						<div>
							<button
								class="btn btn-primary"
								v-on:click="load_energy_devices(true)"
							>
								<Icon icon="sync-alt" />
							</button>
						</div>
					</div>
				</div>

				<div class="content">
					<div class="flex-auto-full-height-content">
						<div class="content relative">
							<div class="absolute full-width full-height overflow-y-scroll">
								<DataTable
									:items="devices"
									:items_per_page="30"
									:fields="table_columns"
								>
									<template #cell(status_provided.value)="data">
										<AssetStatusCircle :status="data.value" />
									</template>

									<template #cell(options)="data">
										<div
											class="primary-color cursor-pointer"
											v-on:click="selected_device = data.item"
										>
											<Icon icon="eye" size="25" />
										</div>
									</template>
								</DataTable>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import EnergyDeviceInfo from "./EnergyDeviceInfo";
import DataTable from "@sub_components/DataTable.vue";
import AssetStatusCircle from "../../../../assets-dashboard/assets-group-assets/AssetStatusCircle.vue";

import EnergyDevicesFinderClass from "@src/energy-device/application/energy-devices-finder.js";
const EnergyDevicesFinder = new EnergyDevicesFinderClass();

export default {
	name: "EnergySystemDevices",
	components: {
		Loader,
		Alert,
		EnergyDeviceInfo,
		DataTable,
		AssetStatusCircle
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: true,
			error: null,
			devices: null,
			selected_device: null
		};
	},
	mounted() {
		this.load_energy_devices();
	},
	methods: {
		async load_energy_devices(force_download = false) {
			try {
				this.loading = true;

				this.devices =
					await EnergyDevicesFinder.get_energy_devices_by_system_id(
						this.energy_system.id,
						force_download
					);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		table_columns() {
			return [
				{
					key: "status_provided.value",
					label: this.$t("dashboard.summary.status")
				},
				{
					key: "extra_data.model",
					label: this.$t("entities.energy_device.extra_data.model"),
					sortable: true
				},
				{
					key: "extra_data.serial_number",
					label: this.$t("entities.energy_device.extra_data.serial_number"),
					sortable: true
				},
				{
					key: "options",
					class: "text-center",
					label: this.$t("user_settings.energy_systems_manager.data_sheet")
				}
			];
		}
	}
};
</script>

<style lang="css" scoped>
#selected-device {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 3;
}
</style>
