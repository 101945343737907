import Joi from "joi";
import Validator from "@validators/validator";
import Timezones from "@entities/timezones";

const timezone_validator = (value, helpers) => {
	//If value not in models throw error
	if (Timezones.indexOf(value) == -1) {
		return helpers.error("any.invalid");
	}
	// Return the value unchanged
	return value;
};

const maintainer_data_schema = Joi.object({
	company_name: Joi.string(),
	phone: Joi.string(),
	address: Joi.string(),
	zones: Joi.array(),
	distance_cost: Joi.number().min(0),
	hour_cost: Joi.number().min(0),
	other_contacts: Joi.array().items(
		Joi.object({
			name: Joi.string().required(),
			phone: Joi.string().allow(null),
			email: Joi.string()
				.email({ tlds: { allow: false } })
				.allow(null),
			position: Joi.string().allow(null)
		})
	)
});

const guest_data_schema = Joi.object({
	guest_id: Joi.string(),
	phone: Joi.string(),
	address: Joi.string(),
	customer_since: Joi.date(),
	vat_number: Joi.string()
});

const user_schema = Joi.object({
	id: Joi.string(),
	first_name: Joi.string().required(),
	last_name: Joi.string(),
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required(),
	timezone: Joi.custom(timezone_validator, "Timezone validation"),
	password: Joi.string(),
	custom_color: Joi.string().regex(/^#[A-Fa-f0-9]{6}/),
	company: Joi.string(),
	unit_system: Joi.string().valid("si", "us", "uk"),
	creation: Joi.date(),
	avatar: Joi.string().uri(),
	assets_watermark: Joi.string().uri(),
	has_access_all_assets: Joi.boolean(),
	allowed_assets: Joi.array().items(Joi.string()),
	supervisor_id: Joi.string(),
	manager_id: Joi.string(),
	roles: Joi.array()
		.items(
			Joi.string().valid(
				"admin",
				"supervisor",
				"manager",
				"guest",
				"maintainer"
			)
		)
		.required(),
	guest_role_extra_data: guest_data_schema,
	maintainer_role_extra_data: maintainer_data_schema,
	ticketing_settings: Joi.object({
		default_distance_cost: Joi.number().positive(),
		default_hour_cost: Joi.number().positive()
	}),
	use_custom_interface: Joi.boolean(),
	language: Joi.string().valid("en", "es", "ca", "it")
});

export default new Validator(user_schema, "user_settings.user_entity.");
