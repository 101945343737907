<template>
	<div
		id="plus-button"
		class="full-rounded blue1-color text-center flex-center-content"
	>
		<Icon icon="plus" size="15" />
	</div>
</template>

<style>
#plus-button {
	width: 20px;
	height: 20px;
	line-height: 30px;
	border: 1px solid var(--blue1);
}
</style>
