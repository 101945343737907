import BackendInterface from "@src/shared/infrastructure/backend-interface";

import LocalObjectConverter from "../../shared/infrastructure/backend-converters/local-object-converter";
import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_energy_system_wind_power_data(
		energy_system_id,
		energy_system_pv_data
	) {
		let formatted_data = LocalObjectConverter(energy_system_pv_data);

		const url =
			"/api/energy-data/systems/" + energy_system_id + "/wind-power-data";
		const backend_updated_data = await this.put(url, {
			body_params: formatted_data
		});

		const local_updated_energy_system =
			BackendEnergySystemConverter(backend_updated_data);

		return local_updated_energy_system;
	}
}
