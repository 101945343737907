<template>
	<div class="white-bg rounded-s" style="width: 500px">
		<PageHeader
			:text="$t('asset_energy_panel.download_data')"
			:on_close_click="close_tool"
		/>
		<div class="p-sm">
			<div v-if="loading">
				<Loader size="30" />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<div v-else-if="download_percentage != null">
				<LoaderBar
					:percentage="loading_data.processed_percentage"
					:show_percentage="true"
				/>
			</div>
			<div v-else>
				<div v-if="download_error" class="m-b">
					<Alert :message="download_error" />
				</div>

				<div class="display-flex m-t p-b-l gap-s">
					<div class="flex-vertical-center">{{ $t("general.period") }}</div>

					<DatePicker
						v-model="selected_period"
						:allow_from="asset_energy_data_first_date"
						:allow_to="asset_energy_data_last_date"
						:is_range="true"
						:max_range_days="allowed_datepickers_days_range"
					/>
				</div>

				<div class="m-t flex-horizontal-right">
					<button class="btn btn-grey" v-on:click="download('xlsx')">
						{{ $t("asset_energy_panel.download_excel") }}
					</button>
					<button class="btn btn-grey m-l" v-on:click="download('csv')">
						{{ $t("asset_energy_panel.download_csv") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader.vue";
import DatePicker from "@form_components/Datepicker.vue";
import LoaderBar from "@sub_components/LoaderBar.vue";

import moment from "moment";

import PvMeasuredVariables from "@entities/variables/pv-measured-data";
import AssetHourlyEnergyFinderClass from "../../../../../../../src/asset-energy-data/application/asset-hourly-energy-finder.js";
import AssetEnergyDataAvailableDateRangeFinderClass from "../../../../../../../src/asset-energy-data/application/asset-energy-data-available-date-range-finder.js";

const AssetHourlyEnergyFinder = new AssetHourlyEnergyFinderClass();
const AssetEnergyDataAvailableDateRangeFinder =
	new AssetEnergyDataAvailableDateRangeFinderClass();

export default {
	name: "AssetEnergyMeasurementsExport",
	components: {
		Loader,
		Alert,
		PageHeader,
		DatePicker,
		LoaderBar
	},
	data() {
		return {
			allowed_datepickers_days_range: 120,
			loading: true,
			error: null,
			download_error: null,
			download_percentage: null,
			asset_energy_data_first_date: null,
			asset_energy_data_last_date: null,
			selected_period: [],
			loading_data: {
				total_promises: 0,
				processed_promises: 0,
				processed_percentage: 0
			}
		};
	},
	async mounted() {
		try {
			const asset_id = this.$route.params.asset_id;

			const asset_energy_data_available_date_range =
				await AssetEnergyDataAvailableDateRangeFinder.get_asset_energy_data_available_date_range(
					asset_id
				);
			this.asset_energy_data_first_date =
				asset_energy_data_available_date_range.energy_measurements.hourly.from;
			this.asset_energy_data_last_date =
				asset_energy_data_available_date_range.energy_measurements.hourly.to;

			const date_to = this.asset_energy_data_last_date;
			const date_from = moment(date_to).subtract(7, "days").toDate();

			this.selected_period = [date_from, date_to];
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		close_tool() {
			this.$emit("close");
		},
		async download(file_type) {
			this.download_percentage = 0;

			try {
				const asset_id = this.$route.params.asset_id;
				const from = this.selected_period[0];
				const to = this.selected_period[1];

				const days_diff = moment(to).diff(from, "days");
				const days_to_download = days_diff + 1;

				var promises = [];
				var promises_result = [];
				for (let index = 0; index < days_to_download; index++) {
					const day_to_request = moment(from).add(index, "days").toDate();

					promises.push(async () => {
						const day_result =
							await AssetHourlyEnergyFinder.get_asset_day_hourly_energy(
								asset_id,
								day_to_request,
								true
							);

						if (day_result && day_result.length > 0) {
							promises_result[index] = day_result;
						}
					});
				}

				await this.$execute_promise_functions_in_batches(
					promises,
					4,
					this.loading_data
				);

				const result = promises_result.flat();

				let result_variables = ["time"];
				let energy_variables = Object.keys(result[0]).filter(
					(item) => item != "time"
				);
				result_variables = result_variables.concat(energy_variables);
				result_variables = result_variables.filter(
					(variable) =>
						variable == "time" ||
						result.find((row) => this.$is_number(row[variable]))
				);

				let result_as_array = [];
				result_as_array.push(
					result_variables.map((item) =>
						this.$t(
							item == "time" ? "dashboard.time" : PvMeasuredVariables[item].name
						)
					)
				);

				result.forEach((result_row) => {
					let row = [];

					result_variables.forEach((variable) => {
						row.push(
							variable == "time"
								? this.$format_date_to_day_hour_minute(result_row[variable])
								: this.$format_number(result_row[variable])
						);
					});

					result_as_array.push(row);
				});

				switch (file_type) {
					case "xlsx":
						this.$download_array_as_xlsx_file(result_as_array, "Export");
						break;
					case "csv":
						this.$download_array_as_csv_file(result_as_array, "Export");
						break;
					default:
						break;
				}
			} catch (error) {
				this.download_error = error;
				setTimeout(() => {
					this.download_error = null;
				}, 8000);
			} finally {
				this.download_percentage = null;
			}
		}
	}
};
</script>

<style scoped></style>
