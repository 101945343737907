<template>
	<div
		id="ticket-edit"
		class="white-bg rounded-s overflow-y-scroll box-shadow1 absolute_sm full-width_xs"
	>
		<div class="full-width full-height flex-auto-full-height-content">
			<div class="header">
				<PageHeader
					:text="
						new_ticket
							? $t('ticketing.new_ticket')
							: $t('ticketing.update_ticket')
					"
					close_name="ticketing"
				/>
			</div>
			<div class="content">
				<div class="p-sm grey1-color">
					<div v-if="ticket_loading">
						<Loader size="30" />
					</div>
					<div v-else-if="big_error">
						<Alert type="danger" :message="big_error" />
					</div>
					<div v-else>
						<div v-if="error">
							<Alert type="danger" :message="error" />
						</div>
						<div v-if="!disable">
							<div>
								<div class="grid-with-same-cols-width-and-padding">
									<div>
										<span>{{ $t("ticketing.ticket_entity.title") }}</span>
									</div>
									<div>
										<span>{{
											$tc("ticketing.ticket_entity.asset_id", 1)
										}}</span>
									</div>
								</div>
								<div class="grid-with-same-cols-width-and-padding">
									<div>
										<input
											type="text"
											class="form-control"
											v-model="ticket.title"
											v-bind:class="{
												'danger-border':
													error &&
													error.type == 'validation' &&
													error.field == 'title'
											}"
										/>
									</div>
									<div>
										<LocationSelect
											v-model="ticket.asset_id"
											:track_by_id="true"
											@input="asset_modified()"
											v-bind:class="{
												'danger-border':
													error &&
													error.type == 'validation' &&
													error.field == 'asset_id'
											}"
										/>
									</div>
								</div>
							</div>
							<div class="m-t">
								<div>
									<span>{{ $t("general.description") }}</span>
								</div>
								<div>
									<textarea
										class="form-control"
										v-model="ticket.description"
									></textarea>
								</div>
							</div>
							<div>
								<div class="grid-with-same-cols-width-and-padding m-t">
									<div>
										<span>{{
											$t("ticketing.ticket_entity.faulty_equipments")
										}}</span>
									</div>
									<div>
										<span>{{ $t("general.status") }}</span>
									</div>
								</div>
								<div class="grid-with-same-cols-width-and-padding">
									<div>
										<CustomMultiselect
											v-if="equipments"
											track_by="id"
											label="name"
											:multiple="true"
											:close-on-select="false"
											v-model="ticket.faulty_equipments"
											:options="equipments"
											placeholder="Select an option"
										/>
									</div>
									<div>
										<CustomMultiselect
											v-if="status"
											track_by="id"
											label="name"
											:multiple="false"
											:close-on-select="false"
											v-bind:class="{
												'danger-border':
													error &&
													error.type == 'validation' &&
													error.field == 'status'
											}"
											v-model="ticket.status"
											:options="status"
											placeholder="Select an status"
										/>
									</div>
								</div>
							</div>
							<div class="m-t">
								<div>
									<span>{{ $t("ticketing.ticket_entity.assigned_to") }}</span>
								</div>
								<div>
									<div v-if="!ticket.asset_id">
										<input class="form-control" disabled />
									</div>
									<CustomMultiselect
										v-else-if="maintainers && ticket.asset_id"
										track_by="id"
										label="first_name"
										:multiple="false"
										v-bind:class="{
											'danger-border':
												error &&
												error.type == 'validation' &&
												error.field == 'assigned_to'
										}"
										v-model="ticket.assigned_to"
										:options="maintainers"
										placeholder="Select an option"
									/>
									<div v-else>
										<Alert
											type="info"
											:message="$t('ticketing.errors.location_no_maintainers')"
										/>
									</div>
								</div>
							</div>
							<div class="m-t">
								<div class="left">
									<span>{{ $t("ticketing.required_visit") }}</span>
								</div>
								<div class="left m-l">
									<CustomCheckbox v-model="visit" />
								</div>
								<div class="clear"></div>
							</div>

							<div v-if="visit">
								<div class="grid-with-same-cols-width-and-padding m-t">
									<div>
										<div class="text-center">
											{{ $t("ticketing.ticket_entity.visit.date") }}
										</div>
										<div>
											<Datepicker
												v-model="ticket.visit.date"
												min-view="hour"
											></Datepicker>
										</div>
									</div>

									<div>
										<div class="text-center">
											{{ $t("ticketing.ticket_entity.visit.hours") }}
										</div>
										<div>
											<NumberInput v-model="ticket.visit.hours" />
										</div>
									</div>

									<div>
										<div class="text-center">
											{{ $t("ticketing.ticket_entity.visit.distance") }}
										</div>
										<div>
											<NumberInput v-model="ticket.visit.distance" />
										</div>
									</div>

									<div>
										<div class="text-center">
											{{ $t("ticketing.ticket_entity.visit.operators") }}
										</div>
										<div>
											<NumberInput v-model="ticket.visit.operators" />
										</div>
									</div>
								</div>

								<div>
									<div class="grid-with-same-cols-width-and-padding m-t">
										<div>
											{{ $t("ticketing.ticket_entity.visit.materials") }}
										</div>
										<div>
											{{ $t("ticketing.ticket_entity.visit.paid_by") }}
										</div>
									</div>
									<div class="grid-with-same-cols-width-and-padding m-t">
										<div>
											<textarea
												class="form-control"
												v-model="ticket.visit.materials"
											></textarea>
										</div>
										<div>
											<CustomMultiselect
												v-if="paid_by"
												track_by="id"
												label="name"
												:multiple="false"
												v-model="ticket.visit.paid_by"
												:options="paid_by"
												:placeholder="$t('general.select_option')"
											/>
										</div>
									</div>
								</div>
								<div class="m-t">
									<div>
										{{ $t("ticketing.ticket_entity.visit.description") }}
									</div>
									<textarea
										class="form-control"
										v-model="ticket.visit.description"
									></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer p-sm border-t">
				<div v-if="loading" class="margin-center">
					<Loader size="30" />
				</div>
				<div v-else class="right btn btn-primary" v-on:click="save_ticket">
					{{ $t("general.save") }}
				</div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";
import PageHeader from "@sub_components/PageHeader";
import LocationSelect from "@form_components/LocationSelect2";
import CustomCheckbox from "@form_components/CustomCheckbox";
import CustomMultiselect from "@form_components/Multiselect";
import Datepicker from "@form_components/Datepicker";
import NumberInput from "@form_components/NumberInput";

import TicketFinderClass from "../../../../../src/ticket/application/ticket-finder.js";
import TicketStatusFinderClass from "../../../../../src/ticket/application/ticket-status/ticket-status-finder.js";

import TicketServiceClass from "@services/user-services/ticket-service";

const TicketFinder = new TicketFinderClass();
const TicketStatusFinder = new TicketStatusFinderClass();
const TicketService = new TicketServiceClass();

export default {
	name: "Ticket",
	components: {
		PageHeader,
		LocationSelect,
		CustomCheckbox,
		CustomMultiselect,
		Datepicker,
		Alert,
		Loader,
		NumberInput
	},
	data() {
		return {
			error: null,
			big_error: null,
			disable: false,
			loading: false,
			ticket_loading: false,
			new_ticket: true,
			visit: false,
			status: null,
			maintainers: null,
			equipments: null,
			paid_by: null,
			ticket: {
				asset_id: undefined,
				assigned_to: {
					id: undefined
				},
				status: undefined,
				title: undefined,
				description: undefined,
				faulty_equipments: undefined,
				visit: {
					date: undefined,
					hours: undefined,
					distance: undefined,
					operators: undefined
				}
			}
		};
	},
	async mounted() {
		this.get_status();
		this.get_equipments();
		this.get_paid_by();

		if (this.$route.params.ticket_id) {
			this.ticket_loading = true;
			try {
				this.ticket = await TicketFinder.get_ticket(
					this.$route.params.ticket_id
				);
				this.check_visit();
				if (this.ticket.assigned_to && this.ticket.assigned_to.id)
					this.ticket.assigned_to = this.ticket.assigned_to.id;
				this.new_ticket = false;
			} catch (e) {
				if (e.code && e.code == 404) {
					this.disable = true;
					this.big_error = "ticketing.errors.ticket_not_exist";
				} else {
					this.big_error = "general.errors.general_error";
				}
			} finally {
				this.ticket_loading = false;
			}
		}

		this.get_asset_maintainers();
	},
	methods: {
		async get_equipments() {
			this.equipments = await TicketService.get_ticketing_faulty_equipments();
		},
		async get_status() {
			this.status = await TicketStatusFinder.get_ticket_status();
		},
		async get_paid_by() {
			this.paid_by = await TicketService.get_ticketing_paid_by();
		},
		async get_asset_maintainers() {
			const selected_asset_id = this.ticket.asset_id;

			this.maintainers = await TicketService.get_allowed_assigned_to(
				selected_asset_id
			);
		},
		async asset_modified() {
			await this.get_asset_maintainers();
		},
		async save_ticket() {
			this.error = null;

			try {
				this.loading = true;
				const ticket_to_edit = Object.assign({}, this.ticket);

				if (this.new_ticket) {
					await TicketService.create_ticket(ticket_to_edit);
					this.new_ticket = false;
				} else {
					await TicketService.update_ticket(ticket_to_edit);
				}
				this.$emit("refresh_tickets");
				this.$router.push({ name: "ticketing" });
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		check_visit() {
			if (!this.ticket.visit)
				this.ticket.visit = {
					date: undefined,
					hours: undefined,
					distance: undefined,
					operators: undefined
				};
			else this.visit = true;
		}
	}
};
</script>

<style scoped>
#ticket-edit {
	width: 700px;
	height: calc(100% - 90px);
}
</style>
