import AssetEnergySystemPvDataUpdaterInterfaceClass from "../infrastructure/energy-system-wind-power-data-updater-interface.js";
import EnergySystemWindPowerDataValidator from "../domain/energy-system-wind-power-data-validator.js";
import EnergySystemFinderValidator from "../domain/energy-system-finder-validator";

import EnergySystemStoreUtil from "@src/energy-system/application/utils/energy-system-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetEnergySystemPvDataUpdaterInterfaceClass();
		this.store_util = EnergySystemStoreUtil;
		this.validator = EnergySystemWindPowerDataValidator;
		this.finder_validator = EnergySystemFinderValidator;
	}

	async update_energy_system_wind_power_data(
		energy_system_id,
		energy_system_wind_power_data
	) {
		const validated_data = this.validator.validate(
			energy_system_wind_power_data
		);

		try {
			const interface_data =
				await this.backend_interface.update_energy_system_wind_power_data(
					energy_system_id,
					validated_data
				);

			const validated_interface_data =
				this.finder_validator.validate(interface_data);

			await this.store_util.add_or_update_item(validated_interface_data);

			return validated_interface_data;
		} catch (e) {
			throw new AppError(
				"error_update_energy_system_wind_power_data",
				"general.errors.general_error",
				e
			);
		}
	}
}
