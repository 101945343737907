<template>
	<div class="full-width full-height relative grey1-color">
		<div
			class="absolute full-width full-height overflow-y-scroll white-bg p-sm rounded-s box-shadow"
		>
			<div>
				<div class="font-xxl font-700">Colors</div>

				<div class="m-t">
					<ColorExamples />
				</div>
			</div>

			<hr />

			<div>
				<div class="font-xxl font-700">Icons</div>

				<div class="display-flex flex-row-wrap gap-xl m-t">
					<div v-for="icon in v2_icons" class="text-center display-grid gap-s">
						<div>
							<Icon :icon="icon" size="50" />
						</div>
						<div class="font-s">
							<span>{{ icon }}</span>
						</div>
					</div>
				</div>
			</div>

			<hr />

			<div>
				<div class="font-xxl font-700">Form inputs</div>
				<div class="m-t">
					<FormInputExample />
				</div>
			</div>

			<hr />

			<div>
				<div class="font-xxl font-700">Button</div>
				<div class="m-t">
					<ButtonExamples />
				</div>
			</div>

			<hr />

			<div>
				<div class="font-xxl font-700">Loader</div>
				<div class="m-t">
					<LoaderExample />
				</div>
			</div>

			<hr />

			<div>
				<div class="font-xxl font-700">Tooltip</div>
				<div class="m-t">
					<TooltipExample />
				</div>
			</div>

			<hr />

			<div>
				<div class="font-xxl font-700">Alert</div>
				<div class="m-t">
					<AlertExample />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ColorExamples from "./ColorExamples.vue";
import CustomIconsV2 from "@sub_components/custom-icon/custom-icons-svg.json";
import ButtonExamples from "./ButtonExamples.vue";
import LoaderExample from "./LoaderExample.vue";
import TooltipExample from "./TooltipExample.vue";
import AlertExample from "./AlertExample.vue";
import FormInputExample from "./FormInputExample.vue";

export default {
	name: "AdminUi",
	components: {
		ColorExamples,
		ButtonExamples,
		LoaderExample,
		TooltipExample,
		AlertExample,
		FormInputExample
	},
	data() {
		return {
			v2_icons: Object.keys(CustomIconsV2)
		};
	}
};
</script>
