<template lang="html">
	<div class="full-width full-height p-xxs">
		<div v-if="loading" class="m-t-xl">
			<Loader />
		</div>
		<div v-else class="full-width full-height">
			<SendReport
				v-if="show_send_report_popup"
				:filter="filter"
				:report_file="report_file"
				v-on:close="show_send_report_popup = false"
			/>

			<div v-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div
				v-else
				class="full-width full-height grey2-bg flex-center-content relative"
			>
				<div class="asset-report-main-div relative">
					<div id="download-pdf-button">
						<ReportActionButtons
							v-on:edit="show_edit_report"
							v-on:export="download_pdf"
							:downloading_pdf="downloading_pdf"
							v-on:share="share_pdf"
							:loading_send_report="loading_send_report"
						/>
					</div>

					<div
						ref="pdf_content"
						class="asset-report-viewer overflow-y-scroll p-t p-b"
					>
						<div class="report-page">
							<Cover
								:report_title="$t('asset_reports.injection0_report.title')"
								:asset="asset"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
							/>
						</div>

						<div class="report-page">
							<Page1
								:asset_pv_report="asset_pv_report"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
							/>
						</div>

						<div class="report-page">
							<Page2
								:asset_pv_report="asset_pv_report"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
							/>
						</div>

						<div v-if="report_edited_data" class="report-page">
							<ReportEditedData :report_edited_data="report_edited_data" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import Cover from "../reports-shared-components/Cover";
import Page1 from "./report-pages/Page1.vue";
import Page2 from "./report-pages/Page2.vue";
import ReportEditedData from "../reports-shared-components/report-edited-data/ReportEditedData.vue";
import ReportActionButtons from "../reports-shared-components/ReportActionButtons.vue";
import SendReport from "../reports-shared-components/SendReport.vue";

import AssetPvReportFinderClass from "@src/asset-pv-report/application/asset-pv-report-finder";
const AssetPvReportFinder = new AssetPvReportFinderClass();

export default {
	name: "AssetMonthlySavingsReport",
	components: {
		Alert,
		Loader,
		Cover,
		Page1,
		Page2,
		ReportEditedData,
		ReportActionButtons,
		SendReport
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object,
		report_edited_data: [Object, null]
	},
	data() {
		return {
			loading: null,
			error: null,
			asset_pv_report: null,
			report_comment: null,
			downloading_pdf: false,
			loading_send_report: false,
			show_send_report_popup: false,
			report_file: null
		};
	},
	mounted() {
		this.generate_report();
	},
	methods: {
		async generate_report() {
			this.loading = true;
			this.error = null;

			try {
				const asset_pv_report = await AssetPvReportFinder.get_asset_pv_report(
					this.asset.id,
					this.filter.date[0],
					this.filter.date[1]
				);
				this.asset_pv_report = Object.assign({}, asset_pv_report);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		async download_pdf() {
			try {
				this.downloading_pdf = true;

				const html_element = this.$refs.pdf_content;
				await this.$download_asset_report_as_pdf_file(
					html_element.innerHTML,
					"asset-injection0-report"
				);
			} catch (error) {
			} finally {
				this.downloading_pdf = false;
			}
		},
		async share_pdf() {
			try {
				this.loading_send_report = true;

				const ref_content = this.$refs.pdf_content;
				const pdf_file = await this.$get_asset_report_as_pdf_file(
					ref_content.innerHTML,
					"asset-injection0-report"
				);
				this.report_file = pdf_file;

				this.show_send_report_popup = true;
			} catch (error) {
			} finally {
				this.loading_send_report = false;
			}
		},
		show_edit_report() {
			this.$emit("show_edit_report");
		},
		comment_edited(comment) {
			this.report_comment = comment;
		}
	},
	watch: {
		filter() {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 50);
		}
	}
};
</script>

<style lang="css" scoped>
#download-pdf-button {
	position: absolute;
	right: 15px;
	top: 25px;
	z-index: 1;
}
</style>

<style lang="css">
.asset-monthly-savings-report-title {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 17px;
}
</style>
