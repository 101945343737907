<template lang="html">
	<div v-if="user">
		<div v-if="validation_error">
			<Alert :validation_error="validation_error" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="success">
			<Alert type="success" message="general.update_successful" />
		</div>

		<div class="m-t font-bold">
			<div>
				{{ $t("user_settings.user_entity.first_name") }}
			</div>
			<div>
				<input type="text" class="form-control" v-model="user.first_name" />
			</div>
		</div>

		<div class="m-t font-bold">
			<div>
				{{ $t("user_settings.user_entity.last_name") }}
			</div>
			<div>
				<input type="text" class="form-control" v-model="user.last_name" />
			</div>
		</div>

		<div class="m-t font-bold">
			<div>
				{{ $t("user_settings.user_entity.company") }}
			</div>
			<div>
				<input type="text" class="form-control" v-model="user.company" />
			</div>
		</div>

		<div class="m-t">
			<div class="font-bold">
				{{ $t("user_settings.user_entity.unit_system") }}
			</div>

			<UnitSystemSelect v-model="user.unit_system" />
		</div>

		<div class="m-t">
			<div class="font-bold">
				{{ $t("entities.user.language") }}
			</div>

			<Multiselect
				v-model="user.language"
				:options="[
					{
						key: 'en',
						label: $t('entities.user.language_options.en')
					},
					{
						key: 'es',
						label: $t('entities.user.language_options.es')
					},
					{
						key: 'ca',
						label: $t('entities.user.language_options.ca')
					},
					{
						key: 'it',
						label: $t('entities.user.language_options.it')
					}
				]"
				:multiple="false"
				track_by="key"
				label="label"
			/>
		</div>

		<div v-if="can_user('edit_own_custom_color')" class="m-t font-bold">
			<div>
				{{ $t("user_settings.user_entity.custom_color") }}
			</div>
			<ColorPicker v-model="color_picker_value" :show_hex_value="true" />
		</div>

		<div class="m-t text-right">
			<div v-if="loading">
				<Loader size="30" />
			</div>
			<div v-else class="btn btn-primary" v-on:click="update_user_settings">
				{{ $t("general.update") }}
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import UnitSystemSelect from "@form_components/UnitSystemSelect";
import Multiselect from "@form_components/Multiselect.vue";

import ColorPicker from "@form_components/ColorPicker";
import CustomCheckbox from "@form_components/CustomCheckbox";

import UserServiceClass from "@services/user-services/user-service";
import UserFinderClass from "@src/user/application/user-finder.js";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const UserService = new UserServiceClass();
const UserFinder = new UserFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "UserSettingsForm",
	components: {
		Loader,
		Alert,
		ColorPicker,
		CustomCheckbox,
		UnitSystemSelect,
		Multiselect
	},
	data() {
		return {
			loading: null,
			error: null,
			validation_error: null,
			user: null,
			color_picker_value: "#FFFFFF",
			success: false
		};
	},
	mounted() {
		this.load_user();
	},
	methods: {
		can_user(permission) {
			return UserPermissionsUtil.can_user(permission);
		},
		async load_user() {
			const user = await UserFinder.get_user();

			this.user = Object.assign({}, user);

			if (this.user.custom_color)
				this.color_picker_value = this.user.custom_color;
		},
		async update_user_settings() {
			this.loading = true;
			this.error = null;
			this.validation_error = null;
			this.success = false;

			try {
				await UserService.change_user_settings(this.user);
				await this.load_user();

				this.success = true;
			} catch (e) {
				if (e.type == "validation") this.validation_error = e;
				else this.error = "general.errors.general_error";
			} finally {
				this.loading = null;
			}
		}
	},
	watch: {
		color_picker_value(new_hex_value) {
			if (new_hex_value) this.user.custom_color = new_hex_value;
		}
	}
};
</script>
