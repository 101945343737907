import AssetEnergyDataAvailableDateRangeFinderInterfaceClass from "../infrastructure/asset-energy-data-available-date-range-finder-interface.js";
import AssetEnergyDataAvailableDateRangeValidator from "../domain/asset-energy-data-available-date-range-validator.js";

import StoreUtilClass from "../../shared/application/utils/store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface =
			new AssetEnergyDataAvailableDateRangeFinderInterfaceClass();
		this.validator = AssetEnergyDataAvailableDateRangeValidator;
		this.store_util = null;
	}

	async get_asset_energy_data_available_date_range(asset_id) {
		const entity_name = "asset_energy_data_available_date_range_" + asset_id;
		this.store_util = new StoreUtilClass(entity_name);

		var asset_energy_data_first_date = this.store_util.get_data();
		if (asset_energy_data_first_date) return asset_energy_data_first_date;

		try {
			const result =
				await this.backend_interface.get_asset_energy_data_available_date_range(
					asset_id
				);

			const validated_result = this.validator.validate(result);

			this.store_util.set_data(
				validated_result,
				moment().add(1, "days").toDate()
			);

			asset_energy_data_first_date = this.store_util.get_data();

			return asset_energy_data_first_date;
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_data_available_date_range",
				"general.errors.general_error",
				e
			);
		}
	}
}
