<template>
	<div class="full-width full-height p-sm">
		<div
			v-if="row1_data && row2_data"
			class="display-flex-vertical-space-between full-height"
		>
			<div class="flex-with-same-cols-width gap-s">
				<div v-for="item in row1_data" class="display-grid gap-s font-bold">
					<div class="flex-center-content">
						<Icon :icon="item.icon" size="30" />
					</div>
					<div class="flex-center-content">
						<span class="font-m">{{ $t(item.multilingual_label) }}</span>
					</div>
					<div class="flex-center-content grey3-bg rounded-s p-t-m p-b-m">
						<div v-if="$is_number(item.value)">
							<span>
								{{ $format_number(item.value, item.value >= 1000 ? 0 : 2) }}
							</span>
							<span class="m-l-xs">kWh</span>
						</div>
						<span v-else> - </span>
					</div>
				</div>
			</div>

			<div class="flex-center-content">
				<div class="flex-with-same-cols-width gap-s" style="width: 75%">
					<div v-for="item in row2_data" class="display-grid gap-s font-bold">
						<div class="flex-center-content">
							<Icon :icon="item.icon" size="30" />
						</div>
						<div class="flex-center-content">
							<span class="font-m">{{ $t(item.multilingual_label) }}</span>
						</div>
						<div class="flex-center-content grey3-bg rounded-s p-t-m p-b-m">
							<div v-if="$is_number(item.value)">
								<span>
									{{ $format_number(item.value, item.value >= 1000 ? 0 : 2) }}
								</span>
								<span class="m-l-xs">kWh</span>
							</div>
							<span v-else> - </span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<Alert type="info" message="general.errors.no_results" />
		</div>
	</div>
</template>

<script>
import Alert from "@alert";

export default {
	name: "LocationOmAcumulatted",
	components: {
		Alert
	},
	props: {
		energy_aggregated_data: Object,
		asset: Object
	},
	data() {
		return {
			row1_data: null,
			row2_data: null
		};
	},
	mounted() {
		const energy_aggregated_data = this.energy_aggregated_data;

		if (energy_aggregated_data) {
			this.row1_data = [
				{
					multilingual_label: "pv_main_panels.generation",
					value: energy_aggregated_data.energy_production,
					icon:
						!this.asset.features.has_pv_production &&
						this.asset.features.has_wind_power_production
							? "wind-turbine"
							: "solar-panel"
				},
				{
					multilingual_label: "pv_main_panels.consumption",
					value: energy_aggregated_data.energy_consumption,
					icon: "home"
				},
				{
					multilingual_label: "pv_main_panels.to_grid",
					value: energy_aggregated_data.energy_to_grid,
					icon: "grid-tower-arrow-up"
				},
				{
					multilingual_label: "pv_main_panels.from_grid",
					value: energy_aggregated_data.energy_from_grid,
					icon: "grid-tower-arrow-down"
				}
			];

			this.row2_data = [
				{
					multilingual_label: "pv_main_panels.solar_consumption",
					value: energy_aggregated_data.energy_self_consumption,
					icon: "sun"
				},
				{
					multilingual_label: "pv_main_panels.to_battery",
					value: energy_aggregated_data.energy_to_battery,
					icon: "battery-arrow-down"
				},
				{
					multilingual_label: "pv_main_panels.from_battery",
					value: energy_aggregated_data.energy_from_battery,
					icon: "battery-arrow-up"
				}
			];
		}
	}
};
</script>

<style lang="css" scoped>
#om-accumulated-table {
	width: 100%;
	table-layout: fixed;
}
#om-accumulated-table .icon-large {
	font-size: 40px;
}
#om-accumulated-table .icon-medium {
	font-size: 35px;
}
#om-accumulated-table .multilingual_label {
	min-height: 50px;
	font-size: 16px;
}
#om-accumulated-table .value-large {
	font-size: 22px;
}
#om-accumulated-table .value-medium {
	font-size: 16px;
}
.units {
	font-size: 12px;
}
</style>
