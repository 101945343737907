import TicketStatusFinderInterfaceClass from "../../infrastructure/ticket-status/ticket-status-finder-interface.js";
import TicketStatusValidator from "../../domain/ticket-status-validator.js";
import TicketStatusStoreUtil from "./ticket-status-store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.validator = TicketStatusValidator;
		this.backend_interface = new TicketStatusFinderInterfaceClass();
		this.store_util = TicketStatusStoreUtil;
	}

	async get_ticket_status() {
		try {
			var cached_ticket_status = this.store_util.get_data();
			if (cached_ticket_status) return cached_ticket_status;

			let result = await this.backend_interface.get_ticket_status();
			const validated_result = result.map((ticket_status) =>
				this.validator.validate(ticket_status)
			);

			this.store_util.set_data(
				validated_result,
				moment().add("6", "hours").toDate()
			);

			return this.store_util.get_data();
		} catch (e) {
			throw new AppError(
				"error_get_ticket_status",
				"general.errors.general_error",
				e
			);
		}
	}
}
