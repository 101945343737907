<template>
	<div>
		<div v-if="loading">
			<Loader size="20" />
		</div>
		<div v-else-if="tickets">
			<div class="p-b m-b-lg" style="border-bottom: 1px solid #dadada">
				<span class="text-uppercase font-bold" style="font-size: 25px">
					{{ $t("asset_reports.shared_components.tickets") }}
				</span>
			</div>

			<table
				class="full-width grey1-color"
				cellspacing="0"
				cellpadding="8"
				style="
					border: none;
					border: 1px solid #acb9db;
					border-collapse: collapse;
				"
			>
				<thead
					style="background-color: #acb9db; font-size: 16px"
					class="font-bold"
				>
					<th
						style="
							border: 1px solid white;
							border-right: none;
							border-left: none;
							border-top: none;
						"
					>
						{{ $t("ticketing.ticket_entity.creation") }}
					</th>
					<th style="border: 1px solid white; border-top: none">
						{{ $t("ticketing.ticket_entity.title") }}
					</th>
					<th style="border: 1px solid white; border-top: none">
						{{ $t("ticketing.ticket_entity.description") }}
					</th>
					<th
						style="
							border: 1px solid white;
							border-right: none;
							border-top: none;
						"
					>
						{{ $t("ticketing.ticket_entity.status") }}
					</th>
				</thead>
				<tbody style="font-size: 14px">
					<tr
						v-for="(ticket, index) in tickets"
						v-bind:style="{
							'background-color': index % 2 == 0 ? '#F4F5F7' : null
						}"
					>
						<td style="border: 1px solid #acb9db" class="font-bold">
							{{ $format_date_to_day(ticket.creation) }}
						</td>
						<td style="border: 1px solid #acb9db">
							{{ ticket.title }}
						</td>
						<td style="border: 1px solid #acb9db">
							{{ ticket.description }}
						</td>
						<td style="border: 1px solid #acb9db">
							{{ get_ticket_status_name(ticket.status) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import Loader from "../../../../../sub_components/Loader.vue";

import TicketFinderClass from "../../../../../../../../src/ticket/application/ticket-finder.js";
import TicketStatusFinderClass from "../../../../../../../../src/ticket/application/ticket-status/ticket-status-finder.js";

const TicketFinder = new TicketFinderClass();
const TicketStatusFinder = new TicketStatusFinderClass();

export default {
	name: "ReportTickets",
	components: {
		Loader
	},
	props: {
		ticket_ids: Array
	},
	data() {
		return {
			loading: false,
			tickets: null,
			ticket_status: null
		};
	},
	async mounted() {
		this.set_tickets();
		this.ticket_status = await TicketStatusFinder.get_ticket_status();
	},
	methods: {
		async set_tickets() {
			this.loading = true;

			try {
				var tickets = [];
				var promises = [];

				for (let index = 0; index < this.ticket_ids.length; index++) {
					promises.push(async () => {
						const key = index;
						const ticket_id = this.ticket_ids[key];

						const ticket = await TicketFinder.get_ticket(ticket_id);
						tickets[key] = ticket;
					});
				}

				await this.$execute_promise_functions_in_batches(promises, 4);

				this.tickets = tickets;
			} catch (error) {
			} finally {
				this.loading = false;
			}
		},
		get_ticket_status_name(ticket_status_id) {
			const ticket_status = this.ticket_status.find(
				(item) => item.id == ticket_status_id
			);

			return ticket_status ? ticket_status.name : null;
		}
	},
	watch: {
		ticket_ids() {
			this.set_tickets();
		}
	}
};
</script>
