import BackendInterface from "../../../shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../../shared/infrastructure/backend-converters/backend-object-converter";

export default class BackendTicketInterface extends BackendInterface {
	constructor() {
		super();
	}

	async get_ticket_status() {
		var result = await this.get("/api/ticketing/status");

		return BackendObjectConverter(result);
	}
}
