import AssetsGroupAssetsFinderInterfaceClass from "../infrastructure/assets-group-assets-summary-finder-interface.js";
import AssetsGroupAssetFinderValidator from "../domain/assets-group-asset-summary-finder-validator.js";
import AssetsGroupAssetsSummaryStoreUtilClass from "./utils/assets-group-assets-summary-store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.validator = AssetsGroupAssetFinderValidator;
		this.backend_interface = new AssetsGroupAssetsFinderInterfaceClass();
		this.store_util = null;
	}

	async get_default_assets_group_assets_summary(force_download = false) {
		return await this.get_assets_group_assets_summary(
			"default",
			force_download
		);
	}

	async get_assets_group_assets_summary(
		assets_group_id,
		force_download = false
	) {
		try {
			this.store_util = new AssetsGroupAssetsSummaryStoreUtilClass(
				assets_group_id
			);

			var cached_asset = this.store_util.get_data();
			if (cached_asset && !force_download) return cached_asset;

			const interface_result =
				await this.backend_interface.get_assets_group_assets_summary(
					assets_group_id
				);

			const validated_data = interface_result.map((item) =>
				this.validator.validate(item)
			);
			this.store_util.set_data(
				validated_data,
				moment().add(30, "minutes").toDate()
			);

			return this.store_util.get_data();
		} catch (e) {
			throw new AppError(
				"error_get_assets_group_assets_summary",
				"general.errors.general_error",
				e
			);
		}
	}
}
