<template lang="html">
	<div class="full-width full-height white-bg">
		<div
			class="flex-auto-full-height-content white-bg rounded-s overflow-hidden"
		>
			<div class="header">
				<AssetReportsHeader v-on:generate_report="generate_report" />
			</div>

			<div v-if="loading" class="flex-center-content full-height">
				<Loader />
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>

			<div v-else class="content">
				<div
					v-if="filter"
					id="report-content"
					class="grey2-bg"
					v-bind:class="{
						'active-edit-report': show_edit_report,
						'p-sm': filter.report_type != 'data_download'
					}"
				>
					<div>
						<AssetPvReport
							v-if="filter.report_type == 'standard'"
							:asset="asset"
							:asset_reports_color="asset_reports_color"
							:filter="filter"
							:report_edited_data="report_edited_data"
							v-on:show_edit_report="show_edit_report = true"
						/>
						<AssetMonthlySavingsReport
							v-else-if="filter.report_type == 'monthly_savings'"
							:asset="asset"
							:asset_reports_color="asset_reports_color"
							:filter="filter"
							:report_edited_data="report_edited_data"
							v-on:show_edit_report="show_edit_report = true"
						/>
						<AssetMonthlySavingsReport2
							v-else-if="filter.report_type == 'monthly_savings_2'"
							:asset="asset"
							:asset_reports_color="asset_reports_color"
							:filter="filter"
							:report_edited_data="report_edited_data"
							v-on:show_edit_report="show_edit_report = true"
						/>
						<AssetInjection0Report
							v-else-if="filter.report_type == 'injection0'"
							:asset="asset"
							:asset_reports_color="asset_reports_color"
							:filter="filter"
							:report_edited_data="report_edited_data"
							v-on:show_edit_report="show_edit_report = true"
						/>
						<AssetsDataDownloadReport
							v-else-if="filter.report_type == 'data_download'"
							:filter="filter"
						/>
					</div>
					<div v-if="show_edit_report">
						<div class="relative full-height">
							<AssetReportEdit
								v-on:hide_edit_report="show_edit_report = false"
								v-model="report_edited_data"
								:asset_id="filter.asset_id"
								:date_from="filter.date[0]"
								:date_to="filter.date[1]"
							/>
						</div>
					</div>
				</div>
				<div v-else class="full-width full-height flex-center-content">
					<div class="text-center">
						<div class="grey1-color font-bold font-xxxxxl">
							<span>{{ $t("asset_reports.menu_label") }}</span>
						</div>
						<div class="grey2-color font-xxl m-t" style="max-width: 750px">
							<span>{{ $t("asset_reports.no_content_string_1") }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import AssetReportsHeader from "./assets-reports-header/AssetReportsHeader.vue";
import AssetPvReport from "./asset-pv-report/AssetPvReport.vue";
import AssetMonthlySavingsReport from "./asset-monthly-savings-report/AssetMonthlySavingsReport.vue";
import AssetMonthlySavingsReport2 from "./asset-monthly-savings-report-2/AssetMonthlySavingsReport2.vue";
import AssetInjection0Report from "./asset-injection0-report/AssetInjection0Report.vue";
import AssetsDataDownloadReport from "./assets-data-download-report/AssetsDataDownloadReport.vue";

import AssetReportEdit from "./reports-shared-components/report-edit/AssetReportEdit.vue";

import AssetFinderClass from "@src/asset/application/assets-finder";
import UserFinderClass from "@src/user/application/user-finder.js";

const AssetFinder = new AssetFinderClass();
const UserFinder = new UserFinderClass();

export default {
	name: "AssetReports",
	components: {
		Alert,
		Loader,
		AssetReportsHeader,
		AssetPvReport,
		AssetMonthlySavingsReport,
		AssetMonthlySavingsReport2,
		AssetInjection0Report,
		AssetsDataDownloadReport,
		AssetReportEdit
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_id: null,
			asset: null,
			asset_reports_color: null,
			filter: null,

			show_edit_report: false,
			report_edited_data: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const user = await UserFinder.get_user();
			this.asset_reports_color = user.custom_color || "#081f56";
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async generate_report(filter) {
			this.loading = true;
			this.show_edit_report = false;
			this.report_edited_data = null;

			try {
				if (filter.asset_id) {
					const asset = await AssetFinder.get_asset_by_id(filter.asset_id);

					if (!asset) {
						throw new Error({
							user_message: "overview.errors.asset_not_exist"
						});
					}

					this.asset = asset;
				}

				this.filter = Object.assign({}, filter);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#no-content > div:first-child {
	margin-top: 150px;
	font-size: 60px;
}
#report-content {
	width: 100%;
	height: 100%;
	display: grid;
}

#report-content.active-edit-report {
	grid-template-columns: -webkit-max-content auto;
	grid-template-columns: max-content auto;
	column-gap: 15px;
}
</style>

<style>
.asset-report-main-div {
	width: calc(800px + 80px);
	position: relative;
	height: 100%;
}
.asset-report-viewer {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0px;
	height: 100%;
}
.report-page {
	padding: 40px 40px 40px;
}
.report-page:not(:first-child) {
	margin-top: 15px;
}
</style>
