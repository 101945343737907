<template>
	<div class="full-width full-height absolute">
		<div class="flex-auto-full-height-content">
			<div class="header">
				<PageHeader :text="$t('asset_reports.shared_components.edit_report')" />
			</div>
			<div class="content p-sm white-bg rounded-s_bottom">
				<div>
					{{ $t("asset_reports.shared_components.edit_report_description") }}
				</div>

				<div class="display-grid gap-xl m-t">
					<div v-for="comment in comments" class="display-grid gap-s">
						<div>
							<TextInput
								:placeholder="
									$t(
										'asset_reports.shared_components.comment_title_description'
									)
								"
								v-model="comment.title"
							/>
						</div>
						<div>
							<TextInput
								:placeholder="
									$t('asset_reports.shared_components.comment_text_description')
								"
								v-model="comment.text"
								:height_in_px="100"
							/>
						</div>
					</div>
				</div>

				<div
					class="m-t-xl display-flex gap-xs cursor-pointer"
					v-on:click="add_new_comment"
				>
					<div>
						<PlusButton />
					</div>
					<div class="flex-center-content">
						<span class="font-bold">
							{{ $t("asset_reports.shared_components.add_new_comment") }}
						</span>
					</div>
				</div>

				<div class="m-t-xl m-b">
					<ReportTicketsEdit
						v-model="tickets"
						:asset_id="asset_id"
						:date_from="date_from"
						:date_to="date_to"
					/>
				</div>

				<div class="flex-horizontal-right gap-s">
					<button class="btn btn-primary" v-on:click="hide_edit_report">
						{{ $t("general.cancel") }}
					</button>

					<button class="btn btn-secondary" v-on:click="edit_report">
						{{ $t("general.add") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "../../../../../sub_components/PageHeader.vue";
import TextInput from "../../../../../sub_components/form_components/TextInput.vue";
import PlusButton from "../../../../../sub_components/PlusButton.vue";
import ReportTicketsEdit from "./ReportTicketsEdit.vue";

export default {
	name: "AssetReportEdit",
	components: {
		PageHeader,
		TextInput,
		PlusButton,
		ReportTicketsEdit
	},
	props: {
		asset_id: String,
		date_from: Date,
		date_to: Date,
		value: [Object, null]
	},
	data() {
		return {
			comments: [
				{
					title: null,
					text: null
				}
			],
			tickets: null,
			emmited_data: null
		};
	},
	mounted() {
		if (this.value) {
			this.comments = JSON.parse(JSON.stringify(this.value.comments));
			this.tickets = JSON.parse(JSON.stringify(this.value.tickets));
		}
	},
	methods: {
		add_new_comment() {
			this.comments.push({
				title: null,
				text: null
			});
		},
		hide_edit_report() {
			this.$emit("hide_edit_report");
		},
		edit_report() {
			const comments_to_emit =
				this.comments && this.comments.length > 0
					? this.comments.filter(
							(item) => item.title != null || item.text != null
					  )
					: null;

			this.emmited_data =
				comments_to_emit || this.tickets
					? JSON.parse(
							JSON.stringify({
								comments:
									comments_to_emit && comments_to_emit.length > 0
										? comments_to_emit
										: null,
								tickets: this.tickets
							})
					  )
					: null;

			this.$emit("input", this.emmited_data);
			this.hide_edit_report();
		}
	},
	watch: {
		value(new_value) {
			this.comments = JSON.parse(JSON.stringify(new_value.comments));
			this.tickets = JSON.parse(JSON.stringify(new_value.tickets));
		}
	}
};
</script>
