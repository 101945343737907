import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async send_emails(subject, emails, html_email_content, files = null) {
		const url = "/api/send-emails";

		await this.post(url, {
			body_params: {
				subject: subject,
				emails: emails,
				htmlEmailContent: html_email_content
			},
			files: files
		});
	}
}
