import BackendInterface from "../../shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

import moment from "moment";

export default class BackendTicketInterface extends BackendInterface {
	constructor() {
		super();
	}

	async get_tickets(
		assets = null,
		created_by = null,
		assigned_to = null,
		status = null,
		faulty_equipments = null,
		creation_from = null,
		creation_to = null
	) {
		var result = await this.get("/api/ticketing/filters", {
			query_params: {
				assetIds: assets && assets.length > 0 ? assets.join(",") : undefined,
				createdByIds:
					created_by && created_by.length > 0
						? created_by.join(",")
						: undefined,
				assignedToIds:
					assigned_to && assigned_to.length > 0
						? assigned_to.join(",")
						: undefined,
				statusIds: status && status.length > 0 ? status.join(",") : undefined,
				faultyEquipmentIds:
					faulty_equipments && faulty_equipments.length > 0
						? faulty_equipments.join(",")
						: undefined,
				creationFrom: creation_from
					? moment(creation_from).startOf("day").toISOString()
					: undefined,
				creationTo: creation_to
					? moment(creation_to).endOf("day").toISOString()
					: undefined
			}
		});

		return BackendObjectConverter(result, {
			assignedTo: {
				formatter: (item) => item.id
			},
			createdBy: {
				formatter: (item) => item.id
			},
			closedAt: {
				formatter: (item) => new Date(item)
			},
			creation: {
				formatter: (item) => new Date(item)
			},
			statusId: {
				local_name: "status"
			},
			faultyEquipmentsIds: {
				local_name: "faulty_equipments"
			},
			requiredVisit: {
				local_name: "visit",
				formatter: (item) => {
					return {
						date: item.visitDate ? new Date(item.visitDate) : undefined,
						hours: item.hours || undefined,
						distance: item.distance || undefined,
						operators: item.operators || undefined,
						materials: item.materials || undefined,
						paid_by: item.paidBy || undefined,
						description: item.description || undefined,
						total_cost: item.totalCosts || 0
					};
				}
			}
		});
	}
}
