<template>
	<div
		class="absolute full-width full-height full-size-popup-bg flex-center-content"
	>
		<div style="width: 600px">
			<PageHeader :text="$t('asset_reports.send_report.header_text')" />
			<div class="white-bg p-sm rounded-s_bottom">
				<div v-if="loading" class="p-t p-b">
					<Loader size="25" />
				</div>
				<div v-else class="display-flex-vertical-space-between gap-s">
					<div v-if="error">
						<Alert :message="error" />
					</div>
					<div v-else-if="success">
						<Alert type="success" message="general.update_successful" />
					</div>
					<div>
						{{ $t("asset_reports.send_report.description") }}
					</div>
					<div>
						<span class="font-bold">
							{{ $t("asset_reports.send_report.email") }}
						</span>
					</div>

					<div
						class="display-flex flex-row-wrap gap-s overflow-y-scroll"
						style="max-height: 500px"
					>
						<div
							v-for="(email, index) in asseets_reports_sender.emails"
							style="width: calc(50% - 5px)"
						>
							<TextInput
								v-model="asseets_reports_sender.emails[index]"
								placeholder="ejemplo@email.com"
							/>
						</div>
					</div>

					<div
						class="blue1-color flex-vertical-center gap-xs cursor-pointer"
						v-on:click="add_email"
					>
						<div
							class="flex-center-content full-rounded"
							style="width: 18px; height: 18px; border: 1px solid"
						>
							<Icon icon="plus" size="13" />
						</div>
						<div>
							<span>{{ $t("asset_reports.send_report.add_email") }}</span>
						</div>
					</div>

					<div class="flex-horizontal-right gap-s">
						<button class="btn btn-secondary" v-on:click="close">
							{{ $t("general.cancel") }}
						</button>
						<button class="btn btn-primary" v-on:click="send_report">
							{{ $t("general.send") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "../../../../sub_components/Loader.vue";
import Alert from "../../../../sub_components/Alert.vue";
import PageHeader from "../../../../sub_components/PageHeader.vue";
import TextInput from "../../../../sub_components/form_components/TextInput.vue";

import moment from "moment";
import AssetsReportsSenderClass from "../../../../../../../src/asset-pv-report/application/assets-reports-sender";
const AssetsReportsSender = new AssetsReportsSenderClass();

export default {
	name: "SendReport",
	components: {
		Loader,
		Alert,
		PageHeader,
		TextInput
	},
	props: {
		report_file: Blob,
		filter: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			asseets_reports_sender: {
				report_type: "data_download",
				emails: [""],
				assets_ids: this.filter.assets_ids
					? this.filter.assets_id
					: [this.filter.asset_id],
				date_from: null,
				date_to: null
			}
		};
	},
	methods: {
		add_email() {
			this.asseets_reports_sender.emails.push("");
		},
		close() {
			this.$emit("close");
		},
		async send_report() {
			this.loading = true;
			this.success = false;
			this.error = null;

			try {
				const filter_date_is_range = Array.isArray(this.filter.date);
				const date_from = filter_date_is_range
					? this.filter.date[0]
					: this.filter.date;
				const date_to = filter_date_is_range
					? this.filter.date[1]
					: this.filter.date;

				switch (this.filter.resolution) {
					case "month":
						this.asseets_reports_sender.date_from = moment(date_from)
							.startOf("month")
							.toDate();
						this.asseets_reports_sender.date_to = moment(date_to)
							.endOf("month")
							.toDate();
						break;
					case "year":
						this.asseets_reports_sender.date_from = moment(date_from)
							.startOf("year")
							.toDate();
						this.asseets_reports_sender.date_to = moment(date_to)
							.endOf("year")
							.toDate();
						break;
					default:
						this.asseets_reports_sender.date_from = date_from;
						this.asseets_reports_sender.date_to = date_to;
						break;
				}

				await AssetsReportsSender.send_assets_reports(
					this.asseets_reports_sender,
					this.report_file
				);

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 5000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>
