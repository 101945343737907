import BackendInterface from "../../shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

import moment from "moment";

export default class BackendTicketInterface extends BackendInterface {
	constructor() {
		super();
	}

	async get_ticket(ticket_id) {
		var result = await this.get("/api/ticketing/tickets/" + ticket_id);

		return BackendObjectConverter(result, {
			assignedTo: {
				formatter: (item) => item.id
			},
			createdBy: {
				formatter: (item) => item.id
			},
			closedAt: {
				formatter: (item) => new Date(item)
			},
			creation: {
				formatter: (item) => new Date(item)
			},
			statusId: {
				local_name: "status"
			},
			faultyEquipmentsIds: {
				local_name: "faulty_equipments"
			},
			requiredVisit: {
				local_name: "visit",
				formatter: (item) => {
					return {
						date: item.visitDate ? new Date(item.visitDate) : undefined,
						hours: item.hours || undefined,
						distance: item.distance || undefined,
						operators: item.operators || undefined,
						materials: item.materials || undefined,
						paid_by: item.paidBy || undefined,
						description: item.description || undefined,
						total_cost: item.totalCosts || 0
					};
				}
			}
		});
	}
}
