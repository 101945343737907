<template lang="html">
	<div class="flex-auto-full-height-content gray-bg">
		<div v-if="!loading && !error && energy_system" class="header p-b">
			<div
				class="display-flex gap-l full-width white-bg p-sm rounded-s box-shadow font-bold grey2-color"
			>
				<router-link :to="{ name: 'user-energy-systems', params: {} }">
					<div>
						<Icon icon="chevron-left" />
					</div>
				</router-link>

				<router-link
					v-for="item in menu"
					:to="{
						name: item.link_name,
						params: {
							system_id: energy_system.id
						}
					}"
					v-bind:class="{ 'primary-color': item.link_name == route_name }"
				>
					{{ $t(item.multilingual_label) }}
				</router-link>
			</div>
		</div>
		<div class="content white-bg rounded-s box-shadow">
			<div v-if="loading" class="p-sm">
				<Loader size="30" />
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else-if="energy_system" class="full-width full-height relative">
				<EditCommonData
					v-if="route_name == 'user-energy-system-update'"
					:energy_system="energy_system"
					v-on:energy_system_updated="energy_system_updated"
				/>
				<router-view
					v-else
					:energy_system="energy_system"
					v-on:energy_system_updated="energy_system_updated"
				></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import EditCommonData from "./EnergySystemEditCommonData";
import EnergySystemEditGenerationData from "./generation-data/EnergySystemEditGenerationData.vue";
import EditConsumptionData from "./consumption-data/EnergySystemEditConsumptionData";
import EditMonthlyData from "./EnergySystemEditMonthlyData";
import EnergySystemDevices from "./devices/EnergySystemDevices";

import EnergySystemFinderClass from "@src/energy-system/application/energy-system-finder";
const EnergySystemFinder = new EnergySystemFinderClass();

export default {
	name: "UserEnergySystemEdit",
	components: {
		Loader,
		Alert,
		EditCommonData,
		EnergySystemEditGenerationData,
		EditConsumptionData,
		EditMonthlyData,
		EnergySystemDevices
	},
	data() {
		return {
			loading: false,
			error: null,
			energy_system: null,
			menu: "general",

			system_has_consumption_data: null,

			system_has_energy_production_data: null,
			system_has_pv_data: null
		};
	},
	async mounted() {
		this.loading = true;
		this.error = null;
		const current_energy_system_id = this.$route.params.system_id;

		try {
			this.energy_system = await EnergySystemFinder.get_energy_system(
				current_energy_system_id
			);

			this.system_has_consumption_data =
				this.energy_system.system_type == "generation_and_consumption" ||
				this.energy_system.system_type == "only_consumption";

			this.system_has_energy_production_data =
				this.energy_system.system_type == "generation_and_consumption" ||
				this.energy_system.system_type == "only_generation";
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		route_name() {
			return this.$route.name;
		},
		menu() {
			let result = [
				{
					link_name: "user-energy-system-update",
					multilingual_label: "user_settings.energy_systems_manager.common_data"
				}
			];

			if (this.system_has_energy_production_data) {
				result.push({
					link_name: "energy-system-edit-generation-data",
					multilingual_label:
						"user_settings.energy_systems_manager.energy_generation_data"
				});
			}

			if (this.system_has_consumption_data) {
				result.push({
					link_name: "energy-system-edit-consumption-data",
					multilingual_label:
						"user_settings.energy_systems_manager.consumption_data"
				});
			}

			if (
				this.energy_system.has_provider &&
				this.energy_system.system_type != "only_grid_interaction"
			) {
				result.push({
					link_name: "energy-system-edit-monthly-data",
					multilingual_label:
						"user_settings.energy_systems_manager.monthly_data"
				});
			}

			if (this.energy_system.has_provider) {
				result.push({
					link_name: "energy-system-edit-energy-devices",
					multilingual_label: "user_settings.energy_systems_manager.devices"
				});
			}

			return result;
		}
	},
	methods: {
		energy_system_updated(energy_system) {
			this.energy_system = energy_system;
		}
	}
};
</script>

<style lang="css" scoped></style>
