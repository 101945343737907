<template>
	<div class="display-flex flex-row-wrap gap-xl">
		<div>
			<div>Standard loader</div>
			<div class="display-flex gap-s m-t">
				<div>
					<Loader size="10" />
				</div>

				<div>
					<Loader size="20" />
				</div>

				<div>
					<Loader size="35" />
				</div>
				<div>
					<Loader size="55" />
				</div>
			</div>
		</div>

		<div style="width: 100%; max-width: 600px">
			<div>Loader Bar</div>
			<div class="m-t">
				<LoaderBar
					:percentage="loader_bar_percentage"
					:show_percentage="true"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "../../../sub_components/Loader.vue";
import LoaderBar from "../../../sub_components/LoaderBar.vue";

export default {
	name: "LoaderExamples",
	components: {
		Loader,
		LoaderBar
	},
	data() {
		return {
			loader_bar_percentage: 0
		};
	},
	mounted() {
		setInterval(this.increment_percentage, 600);
	},
	methods: {
		increment_percentage() {
			this.loader_bar_percentage += 20;
			if (this.loader_bar_percentage > 100) {
				this.loader_bar_percentage = 0;
			}
		}
	}
};
</script>
