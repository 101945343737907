import Joi from "joi";
import Validator from "@validators/validator";

const energy_system_schema = Joi.object({
	id: Joi.string().required(),
	type: Joi.string().valid("inverter").required(),
	system_id: Joi.string().required(),
	provider_id: Joi.string(),
	extra_data: Joi.object({
		model: Joi.string(),
		serial_number: Joi.string(),
		peak_power: Joi.number(),
		nominal_power: Joi.number(),
		dimensions: Joi.string(),
		weight: Joi.number(),
		max_efficiency: Joi.number(),
		european_efficiency: Joi.number(),
		nominal_voltage: Joi.number()
	}),
	status_provided: Joi.object({
		value: Joi.string()
			.valid("ok", "offline", "faulty", "unavailable", "unsupported")
			.required(),
		date: Joi.date().required()
	})
});
export default new Validator(energy_system_schema, "entities.energy_device.");
