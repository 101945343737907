const entities = {
	site_type: {
		solar: "solar",
		wind: "wind",
		weather: "weather",
		consumption: "consumption"
	},
	solar_type: {
		single: "single",
		shared: "shared"
	},
	connection_type: {
		wifi: "wifi",
		wifi_pcl_or_repeater: "wifi_pcl_repeater",
		ethernet: "ethernet",
		"4g": "4g",
		other: "other",
		no_communication: "no_communication"
	},
	facility_type: {
		industrial: "industrial",
		commercial: "commercial",
		residential: "residential"
	},
	pv_data_tracking: {
		0: "fixed",
		1: "1-axis",
		2: "2-axis"
	},
	energy_measurement_provider_names: {
		fronius: "fronius",
		sma: "sma",
		enphase: "enphase",
		"enphase-v4": "enphase-v4",
		ingeteam: "ingeteam",
		wibeee: "wibeee",
		"huawei-fusionsolar": "huawei-fusionsolar",
		"huawei-neteco": "huawei-neteco",
		datadis: "datadis",
		twinmeter: "twinmeter",
		victron: "victron",
		sungrow: "sungrow",
		vmc: "vmc",
		solaredge: "solaredge",
		lacecal: "lacecal",
		solax: "solax",
		sunvecx: "sunvecx",
		growatt: "growatt",
		"growatt-device": "growatt-device",
		custom: "custom",
		solis: "solis",
		"canadian-solar": "canadian-solar",
		kostal: "kostal",
		goodwe: "goodwe",
		renesys: "renesys"
	},
	user_roles: {
		ROLE_GUEST: "guest",
		ROLE_MAINTAINER: "maintainer",
		ROLE_ADMIN: "admin",
		ROLE_SUPERVISOR: "supervisor",
		ROLE_MANAGER: "manager"
	},
	forecast_and_weather: {
		time: "time",
		precipIntensity: "precipitation_intensity",
		precipProbability: "precipitation_probability",
		temperature: "temperature",
		humidity: "humidity",
		windSpeed: "wind_speed",
		windBearing: "wind_bearing",
		windGust: "wind_gust",
		visibility: "visibility",
		cloudCover: "cloud_cover",
		pressure: "pressure",
		ozone: "ozone",
		uvIndex: "uv_index",
		dni: "dni",
		ghi: "ghi",
		dhi: "dhi",
		pv: "pv"
	}
};

export default {
	convert_backend_value_to_local(entity_name, backend_value) {
		return entities[entity_name] && entities[entity_name][backend_value]
			? entities[entity_name][backend_value]
			: null;
	},

	convert_value_to_backend_value(entity_name, value) {
		if (!entities[entity_name]) return null;

		const entity_values = entities[entity_name];
		for (let key in entity_values) {
			if (entity_values[key] == value) return isNaN(key) ? key : parseInt(key);
		}

		return null;
	},

	get_entity_available_options(entity_name) {
		let options = [];

		for (let backend_value in entities[entity_name]) {
			const local_value = entities[entity_name][backend_value];
			options.push(local_value);
		}

		return options;
	}
};
