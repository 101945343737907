<template>
	<div class="display-flex flex-row-wrap gap-m">
		<div>
			<button class="btn btn-primary">.btn-primary</button>
		</div>

		<div>
			<button class="btn btn-primary-inverse">.btn-primary-inverse</button>
		</div>

		<div>
			<button class="btn btn-secondary">.btn-secondary</button>
		</div>

		<div>
			<button class="btn btn-secondary-inverse">.btn-secondary-inverse</button>
		</div>

		<div>
			<button class="btn btn-grey">.btn-grey</button>
		</div>

		<div>
			<button class="btn btn-disabled">.btn-disabled</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "ButtomExamples"
};
</script>
