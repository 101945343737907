import Joi from "joi";
import Validator from "@validators/validator";

const date_range_schema = Joi.object({
	from: Joi.date().required(),
	to: Joi.date().required()
});

const schema = Joi.object({
	energy_measurements: Joi.object({
		hourly: date_range_schema,
		daily: date_range_schema,
		monthly: date_range_schema
	}),
	energy_forecast: Joi.object({
		hourly: date_range_schema
	})
});

export default new Validator(schema);
