<template lang="html">
	<div class="full-width full-height">
		<div v-if="loading" class="flex-center-content full-width full-height">
			<Loader size="30" />
		</div>
		<div v-else class="flex-auto-full-height-content p-sm full-height">
			<div class="content">
				<div v-if="error" class="max-width-fields fixed">
					<Alert :message="error" />
				</div>
				<div v-else-if="success" class="max-width-fields fixed">
					<Alert message="general.update_successful" type="success" />
				</div>

				<div
					class="two-col-fields max-width-fields"
					v-bind:class="{ 'm-t-xxxl': error || success }"
				>
					<div
						class="display-flex-vertical-space-between gap-xs"
						v-for="field_data in wind_power_data_fields_data"
					>
						<div>
							<span>
								{{
									$t("entities.energy_system.wind_power_data." + field_data.key)
								}}
							</span>
							<span v-if="field_data.required"><sup>*</sup></span>
						</div>
						<div>
							<NumberInput
								v-if="field_data.field_type == 'number'"
								v-model="wind_power_data_to_update[field_data.key]"
								:unit="field_data.unit || null"
							/>
							<Multiselect
								v-else-if="
									field_data.field_type == 'custom' &&
									field_data.key == 'wind_turbine_model'
								"
								v-model="wind_power_data_to_update[field_data.key]"
								:options="wind_turbines_options"
								:close_on_select="true"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="footer border-t p-t m-t">
				<button class="btn btn-primary" v-on:click="update_wind_power_data">
					{{ $t("general.save") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";
import NumberInput from "@form_components/NumberInput";
import TextInput from "@form_components/TextInput";
import Multiselect from "../../../../../sub_components/form_components/Multiselect.vue";

import WindTubinesFinder from "../../../../../../../../src/energy-system/application/utils/wind-tubines-finder";
import EnergySystemWindPowerDataUpdaterClass from "../../../../../../../../src/energy-system/application/energy-system-wind-power-data-updater";

const EnergySystemWindPowerDataUpdater =
	new EnergySystemWindPowerDataUpdaterClass();

export default {
	name: "EnergySystemEditWindPowerData",
	components: {
		Alert,
		Loader,
		NumberInput,
		TextInput,
		Multiselect
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			wind_power_data_to_update: this.energy_system.wind_power_data
				? Object.assign({}, this.energy_system.wind_power_data)
				: {},
			wind_turbines_options: WindTubinesFinder.get_wind_turbines_options()
		};
	},
	methods: {
		async update_wind_power_data() {
			this.loading = true;
			this.error = null;

			try {
				const data_to_update = Object.assign(
					{},
					this.wind_power_data_to_update
				);

				const updated_energy_system =
					await EnergySystemWindPowerDataUpdater.update_energy_system_wind_power_data(
						this.energy_system.id,
						data_to_update
					);

				this.wind_power_data_to_update = updated_energy_system.wind_power_data;

				this.$emit("energy_system_updated", updated_energy_system);

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 5000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		wind_power_data_fields_data() {
			return [
				{
					key: "peak_power",
					field_type: "number",
					required: true,
					unit: "kWp"
				},

				{
					key: "nominal_power",
					field_type: "number",
					required: false,
					unit: "kW"
				},
				{
					key: "wind_turbines",
					field_type: "number",
					required: true,
					unit: null
				},
				{
					key: "wind_turbine_model",
					field_type: "custom",
					required: true
				},
				{
					key: "wind_turbine_nominal_power",
					field_type: "number",
					required: true
				},
				{
					key: "hub_height",
					field_type: "number",
					required: true,
					unit: "m"
				},
				{
					key: "rotor_diameter",
					field_type: "number",
					required: true,
					unit: "m"
				},
				{
					key: "losses",
					field_type: "number",
					required: false,
					unit: "%"
				}
			];
		}
	}
};
</script>

<style lang="css" scoped>
.max-width-fields {
	width: 800px;
}

.two-col-fields {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 15px;
	row-gap: 30px;
}
</style>
