<template lang="html">
	<div>
		<div class="display-grid gap-xl">
			<div v-if="report_edited_data.comments">
				<div class="p-b" style="border-bottom: 1px solid #dadada">
					<span class="text-uppercase font-bold" style="font-size: 25px">
						{{ $t("asset_reports.shared_components.comments") }}
					</span>
				</div>

				<div style="margin-top: 20px; display: grid; gap: 25px">
					<div
						v-for="comment in report_edited_data.comments"
						style="display: grid; gap: 10px"
					>
						<div class="font-bold" style="font-size: 18px">
							{{ comment.title }}
						</div>
						<div
							style="font-size: 14px"
							v-html="
								comment.text != null
									? comment.text.replace(/\n/g, '<br>')
									: null
							"
						></div>
					</div>
				</div>
			</div>

			<div
				v-if="
					report_edited_data.tickets && report_edited_data.tickets.length > 0
				"
			>
				<ReportTickets :ticket_ids="report_edited_data.tickets" />
			</div>
		</div>
	</div>
</template>

<script>
import ReportTickets from "./ReportTickets.vue";

export default {
	name: "ReportEditedData",
	components: {
		ReportTickets
	},
	props: {
		report_edited_data: Object
	}
};
</script>

<style lang="css" scoped></style>
