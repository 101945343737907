<template lang="html">
	<div class="full-height">
		<div
			v-if="loading"
			class="full-width full-height white-bg flex-center-content"
		>
			<Loader />
		</div>
		<div v-else class="full-height">
			<SendReport
				v-if="show_send_report_popup"
				:filter="filter"
				:report_file="report_file"
				v-on:close="show_send_report_popup = false"
			/>

			<div v-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else-if="asset && asset_reports" class="full-height relative">
				<div class="full-height grey2-bg flex-center-content">
					<div class="asset-report-main-div">
						<div id="report-action-buttons">
							<ReportActionButtons
								v-on:edit="show_edit_report"
								v-on:export="download_pdf"
								:downloading_pdf="downloading_pdf"
								v-on:share="share_pdf"
								:loading_send_report="loading_send_report"
							/>
						</div>
						<div id="pv-report-error" v-if="error">
							<Alert :message="error" />
						</div>
						<div
							ref="pdf_content"
							class="asset-report-viewer overflow-y-scroll"
						>
							<div class="report-page">
								<Cover
									:report_title="$t('asset_reports.pv_report.title')"
									:asset="asset"
									:asset_reports_filter="filter"
									:asset_reports_color="asset_reports_color"
								/>
							</div>
							<div class="report-page">
								<Page1
									:asset_reports="asset_reports"
									:asset="asset"
									:asset_reports_filter="filter"
									:report_comment="report_comment"
									:asset_reports_color="asset_reports_color"
								/>
							</div>
							<div class="report-page">
								<Page2
									:asset_reports="asset_reports"
									:report_comment="report_comment"
									:asset_reports_color="asset_reports_color"
								/>
							</div>
							<div v-if="report_edited_data" class="report-page">
								<ReportEditedData :report_edited_data="report_edited_data" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import Cover from "../reports-shared-components/Cover";
import Page1 from "./report-pages/Page1";
import Page2 from "./report-pages/Page2";
import ReportEditedData from "../reports-shared-components/report-edited-data/ReportEditedData.vue";
import ReportActionButtons from "../reports-shared-components/ReportActionButtons.vue";
import SendReport from "../reports-shared-components/SendReport.vue";

import AssetPvReportFinderClass from "@src/asset-pv-report/application/asset-pv-report-finder.js";
const AssetPvReportFinder = new AssetPvReportFinderClass();

export default {
	name: "AssetPvReport",
	components: {
		Loader,
		Alert,
		Cover,
		Page1,
		Page2,
		ReportEditedData,
		ReportActionButtons,
		SendReport
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object,
		report_edited_data: [Object, null]
	},
	data() {
		return {
			loading: null,
			error: null,
			asset_reports: null,
			report_comment: null,
			downloading_pdf: null,
			custom_css:
				"/* -- START Page 1 -- */ #pv-report-page1-title { float: right; clear: both; font-size: 30px; margin-top: 30px; } #pv-report-page1-report-description { float: right; clear: both; width: 500px; font-size: 16px; margin-top: 10px; } /* -- END Page 1 -- */ /* -- START Page 2 -- */ #pv-report-page2-top-info { margin-top: 15px; font-size: 14px; line-height: 25px; } #pv-report-page2-report-map { width: 100%; height: 220px; } #pv-report-page2-chart { margin-top: 15px; width: 100%; } #pv-report-page2-chart > div:first-child { display: flex; } #pv-report-page2-chart > div:first-child > div { display: flex; justify-content: center; align-items: center; } #pv-report-page2-table { width: 100%; font-size: 12px; border-collapse: separate; border-spacing: 10px 5px; } #pv-report-page2-table { margin-top: 30px; } /* -- END Page 2 -- */ /* -- START Page 3 -- */ .pv-report-page3-big-numbers { font-size: 25px; font-weight: 700; } .pv-report-page3-ƒont-xs { font-size: 12px; } .pv-report-page3-ƒont-s { font-size: 14px; } #pv-report-page3-selected-period-summary { margin-top: 15px; width: 90%; margin-left: 5%; } #pv-report-page3-selected-period-summary table { width: 100%; table-layout: fixed; text-align: center; }/* -- END Page 3 -- */",

			loading_send_report: false,
			show_send_report_popup: false,
			report_file: null
		};
	},
	mounted() {
		this.generate_report(this.filter);
	},
	methods: {
		async generate_report(filter) {
			this.loading = true;
			this.error = null;

			try {
				const asset_reports = await AssetPvReportFinder.get_asset_pv_report(
					this.asset.id,
					filter.date[0],
					filter.date[1]
				);
				this.asset_reports = Object.assign({}, asset_reports);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		async download_pdf() {
			try {
				this.downloading_pdf = true;

				const ref_content = this.$refs.pdf_content;
				await this.$download_asset_report_as_pdf_file(
					ref_content.innerHTML,
					"asset-pv-report",
					this.custom_css
				);
			} catch (error) {
			} finally {
				this.downloading_pdf = false;
			}
		},
		async share_pdf() {
			try {
				this.loading_send_report = true;
				const ref_content = this.$refs.pdf_content;
				const pdf_file = await this.$get_asset_report_as_pdf_file(
					ref_content.innerHTML,
					"asset-pv-report",
					this.custom_css
				);
				this.report_file = pdf_file;

				this.show_send_report_popup = true;
			} catch (error) {
			} finally {
				this.loading_send_report = false;
			}
		},
		show_edit_report() {
			this.$emit("show_edit_report");
		},
		comment_edited(comment) {
			this.report_comment = comment;
		}
	},
	watch: {
		filter(filter_new_value) {
			this.generate_report(filter_new_value);
		}
	}
};
</script>

<style>
@import "./report-pages/pv-report-styles.css";
</style>

<style lang="css" scoped>
#report-action-buttons {
	position: absolute;
	right: 15px;
	top: 20px;
	z-index: 1;
}
#pv-report-error {
	position: absolute;
	z-index: 1;
	top: 20px;
	left: 15px;
	width: 400px;
}
</style>
