import AssetSavingsMonthlyFinderInterfaceClass from "../infrastructure/asset-savings-monthly-finder-interface";
import AssetSavingsMonthlyFinderValidator from "../domain/asset-savings-monthly-finder-validator";
import AssetEnergyDataAvailableDateRangeFinderClass from "../../asset-energy-data/application/asset-energy-data-available-date-range-finder.js";

import StoreUtilClass from "@src/shared/application/utils/store-util";
import TimeSeriesUtil from "@src/shared/application/utils/time-series-util.js";

import moment from "moment";
import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetSavingsMonthlyFinderInterfaceClass();
		this.validator = AssetSavingsMonthlyFinderValidator;
		this.asset_energy_data_available_date_range_finder =
			new AssetEnergyDataAvailableDateRangeFinderClass();
	}

	async get_asset_savings_monthly(asset_id, force_download = false) {
		const store_util = new StoreUtilClass("asset_savings_monthly_" + asset_id);

		let asset_savings_monthly = await store_util.get_items();
		if (asset_savings_monthly && !force_download) return asset_savings_monthly;

		try {
			const asset_energy_data_available_date_range =
				await this.asset_energy_data_available_date_range_finder.get_asset_energy_data_available_date_range(
					asset_id
				);
			const asset_energy_data_monthly_first_date =
				asset_energy_data_available_date_range.energy_measurements.monthly.from;

			const interface_data =
				await this.backend_interface.get_asset_savings_monthly(
					asset_id,
					asset_energy_data_monthly_first_date,
					new Date()
				);

			const validated_data = this.validator.validate(interface_data);

			await store_util.save_items(validated_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_user_energy_system",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_asset_savings_monhtly_by_date(asset_id, from = null, to = null) {
		const asset_monthly_savings = await this.get_asset_savings_monthly(
			asset_id
		);

		const date_range_data = asset_monthly_savings.filter((item) => {
			if (
				(from && moment(item.month).isBefore(from, "month")) ||
				(to && moment(item.month).isAfter(to, "month"))
			)
				return false;
			else return true;
		});

		return date_range_data && date_range_data.length > 0
			? date_range_data
			: null;
	}

	async get_asset_aggregated_savings_by_date(asset_id, from, to) {
		const asset_monthly_savings_by_date =
			await this.get_asset_savings_monhtly_by_date(asset_id, from, to);

		return asset_monthly_savings_by_date
			? TimeSeriesUtil.time_serie_aggregate(asset_monthly_savings_by_date, [
					"month"
			  ])
			: null;
	}
}
