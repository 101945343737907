import moment from "moment";
import BackendInterface from "./backend-interface";

export default class BackendTicketInterface extends BackendInterface {
	constructor() {
		super();
	}

	async update_ticket(ticket) {
		var result = await this.rest_interface.put(
			this.backend_url + "/api/ticketing/tickets/" + ticket.id,
			{ params: this.ticket_to_backend_object(ticket) }
		);
		return this.backend_obj_to_ticket(result);
	}

	async close_ticket(ticket_id) {
		var result = await this.rest_interface.put(
			this.backend_url + "/api/ticketing/tickets/" + ticket_id + "/close"
		);
		return this.backend_obj_to_ticket(result);
	}

	async remove_ticket(ticket_id) {
		const url = this.backend_url + "/api/ticketing/tickets/" + ticket_id;
		var result = await this.rest_interface.delete(url);
		return result;
	}

	// --- START ticketing status --- //
	async get_ticketing_status() {
		var result = await this.rest_interface.get(
			this.backend_url + "/api/ticketing/status"
		);
		var formated_status = {};
		result.forEach((interface_status) => {
			formated_status[interface_status.id] =
				this._backend_status_to_status(interface_status);
		});
		return formated_status;
	}

	async create_ticketing_status(status) {
		let backend_status = await this.rest_interface.post(
			this.backend_url + "/api/ticketing/status",
			{
				params: {
					name: status.name,
					color: status.color
				}
			}
		);

		return {
			id: backend_status.id,
			name: backend_status.name,
			color: status.color
		};
	}

	async update_ticketing_status(status) {
		let backend_status = await this.rest_interface.put(
			this.backend_url + "/api/ticketing/status/" + status.id,
			{
				params: {
					name: status.name,
					color: status.color
				}
			}
		);

		return {
			id: backend_status.id,
			name: backend_status.name,
			color: backend_status.color
		};
	}

	async remove_ticketing_status(status_id, replacement_status_id = null) {
		const url = this.backend_url + "/api/ticketing/status/" + status_id;

		let request_parameters = null;
		if (replacement_status_id) {
			request_parameters = {
				params: {
					replaceStatusWith: replacement_status_id
				}
			};
		}

		const result = await this.rest_interface.delete(url, request_parameters);
		return result;
	}
	// --- END ticketing status --- //

	// --- START faulty equipments --- //
	async get_ticketing_faulty_equipments() {
		var result = await this.rest_interface.get(
			this.backend_url + "/api/ticketing/faulty-equipments"
		);
		var id_formated = {};
		result.forEach((item) => {
			id_formated[item.id] = item;
		});
		return id_formated;
	}

	async create_ticketing_faulty_equipment(faulty_equipment) {
		let backend_faulty_equipment = await this.rest_interface.post(
			this.backend_url + "/api/ticketing/faulty-equipment",
			{
				params: {
					name: faulty_equipment.name
				}
			}
		);

		return {
			id: backend_faulty_equipment.id,
			name: backend_faulty_equipment.name
		};
	}

	async update_ticketing_faulty_equipment(faulty_equipment) {
		let backend_faulty_equipment = await this.rest_interface.put(
			this.backend_url +
				"/api/ticketing/faulty-equipment/" +
				faulty_equipment.id,
			{
				params: {
					name: faulty_equipment.name
				}
			}
		);

		return {
			id: backend_faulty_equipment.id,
			name: backend_faulty_equipment.name
		};
	}

	async remove_faulty_equipment(
		faulty_equipment_id,
		replacement_faulty_equipment_id = null
	) {
		const url =
			this.backend_url +
			"/api/ticketing/faulty-equipment/" +
			faulty_equipment_id;

		let result;
		if (replacement_faulty_equipment_id) {
			result = await this.rest_interface.delete(url, {
				params: {
					replaceFaultyEquipmentWith: replacement_faulty_equipment_id
				}
			});
		} else {
			result = await this.rest_interface.delete(url);
		}

		return result;
	}
	// --- END faulty equipments --- //

	// --- START paid by --- //
	async get_ticketing_paid_by() {
		let result = await this.rest_interface.get(
			this.backend_url + "/api/ticketing/paids-by"
		);
		var id_formated = {};
		result.forEach((item) => {
			id_formated[item.id] = item;
		});
		return id_formated;
	}

	async create_ticketing_paid_by(paid_by) {
		let backend_paid_by = await this.rest_interface.post(
			this.backend_url + "/api/ticketing/paid-by",
			{
				params: {
					name: paid_by.name
				}
			}
		);

		return {
			id: backend_paid_by.id,
			name: backend_paid_by.name
		};
	}

	async update_ticketing_paid_by(paid_by) {
		let backend_paid_by = await this.rest_interface.put(
			this.backend_url + "/api/ticketing/paid-by/" + paid_by.id,
			{
				params: {
					name: paid_by.name
				}
			}
		);

		return {
			id: backend_paid_by.id,
			name: backend_paid_by.name
		};
	}

	async remove_faulty_equipment(
		faulty_equipment_id,
		replacement_faulty_equipment_id = null
	) {
		const url =
			this.backend_url +
			"/api/ticketing/faulty-equipment/" +
			faulty_equipment_id;

		let result;
		if (replacement_faulty_equipment_id) {
			result = await this.rest_interface.delete(url, {
				params: {
					replaceFaultyEquipmentWith: replacement_faulty_equipment_id
				}
			});
		} else {
			result = await this.rest_interface.delete(url);
		}

		return result;
	}

	async remove_ticketing_paid_by(paid_by_id, replacement_paid_by_id = null) {
		const url = this.backend_url + "/api/ticketing/paid-by/" + paid_by_id;

		let result;
		if (replacement_paid_by_id) {
			result = await this.rest_interface.delete(url, {
				params: {
					replacePaidByWith: replacement_paid_by_id
				}
			});
		} else {
			result = await this.rest_interface.delete(url);
		}

		return result;
	}
	// --- END paid by --- //

	async create_ticket(ticket) {
		const url = this.backend_url + "/api/ticketing/ticket";
		var result = await this.rest_interface.post(url, {
			params: this.ticket_to_backend_object(ticket)
		});
		return this.backend_obj_to_ticket(result);
	}

	ticket_to_backend_object(ticket) {
		let backend_obj = {
			assetId: ticket.asset_id,
			title: ticket.title,
			description: ticket.description || undefined,
			statusId: ticket.status,
			assignedTo: ticket.assigned_to
		};

		if (ticket.faulty_equipments && ticket.faulty_equipments.length > 0) {
			backend_obj.faultyEquipmentsIds = ticket.faulty_equipments;
		}

		if (ticket.visit) {
			backend_obj.requiredVisit = {
				visitDate: ticket.visit.date
					? moment(ticket.visit.date).format("YYYY-MM-DD")
					: undefined,
				hours: ticket.visit.hours || undefined,
				distance: ticket.visit.distance || undefined,
				operators: ticket.visit.operators || undefined,
				materials: ticket.visit.materials || undefined,
				paidBy: ticket.visit.paid_by || undefined,
				description: ticket.visit.description || undefined
			};
		}
		return backend_obj;
	}

	backend_obj_to_ticket(backend_obj) {
		let ticket = {
			id: backend_obj.id,
			title: backend_obj.title,
			creation: backend_obj.creation
				? new Date(backend_obj.creation)
				: undefined,
			asset_id: backend_obj.assetId,
			assigned_to: backend_obj.assignedTo ? backend_obj.assignedTo.id : null,
			created_by: backend_obj.createdBy.id,
			status: backend_obj.statusId,
			description: backend_obj.description || undefined,
			closed_at: backend_obj.closedAt ? new Date(backend_obj.closedAt) : null
		};

		if (
			backend_obj.faultyEquipmentsIds &&
			backend_obj.faultyEquipmentsIds.length > 0
		)
			ticket.faulty_equipments = backend_obj.faultyEquipmentsIds;

		if (backend_obj.requiredVisit) {
			ticket.visit = {
				date: backend_obj.requiredVisit.visitDate
					? new Date(backend_obj.requiredVisit.visitDate)
					: undefined,
				hours: backend_obj.requiredVisit.hours || undefined,
				distance: backend_obj.requiredVisit.distance || undefined,
				operators: backend_obj.requiredVisit.operators || undefined,
				materials: backend_obj.requiredVisit.materials || undefined,
				paid_by: backend_obj.requiredVisit.paidBy || undefined,
				description: backend_obj.requiredVisit.description || undefined,
				total_cost: backend_obj.requiredVisit.totalCosts || 0
			};
		}

		return ticket;
	}

	_backend_status_to_status(backend_obj) {
		return {
			id: backend_obj.id,
			name: backend_obj.name,
			color: backend_obj.color,
			send_mail: backend_obj.sendMail
		};
	}

	/* Ticketing preferences */
	async save_ticketing_settings(ticketing_settings) {
		const url = this.backend_url + "/api/supervisor/settings/ticketing";
		var result = await this.rest_interface.post(url, {
			params: {
				defaultHourCost: ticketing_settings.default_hour_cost,
				defaultDistanceCost: ticketing_settings.default_distance_cost
			}
		});

		return {
			default_hour_cost: result.defaultHourCost,
			default_distance_cost: result.defaultDistanceCost
		};
	}
	/* End ticketing preferences*/
}
