<template lang="html">
	<div class="full-width full-height p-xxs">
		<div v-if="loading" class="m-t-xl">
			<Loader />
		</div>
		<div v-else class="full-width full-height">
			<SendReport
				v-if="show_send_report_popup"
				:filter="filter"
				:report_file="report_file"
				v-on:close="show_send_report_popup = false"
			/>

			<div v-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div
				v-else
				class="full-width full-height grey2-bg flex-center-content relative"
			>
				<div class="asset-report-main-div relative">
					<div id="download-pdf-button">
						<ReportActionButtons
							v-on:edit="show_edit_report"
							v-on:export="download_pdf"
							:downloading_pdf="downloading_pdf"
							v-on:share="share_pdf"
							:loading_send_report="loading_send_report"
						/>
					</div>

					<div
						ref="pdf_content"
						class="asset-report-viewer overflow-y-scroll p-t p-b"
					>
						<div class="report-page">
							<Cover
								:report_title="
									$t('asset_reports.asset_monthly_savings_report.title')
								"
								:asset="asset"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
							/>
						</div>

						<div class="report-page">
							<Page1
								:filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:comment="comment"
							/>
						</div>

						<div class="report-page">
							<Page2
								:filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:comment="comment"
							/>
						</div>
						<div class="report-page">
							<Page3
								:filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:comment="comment"
							/>
						</div>

						<div v-if="report_edited_data" class="report-page">
							<ReportEditedData :report_edited_data="report_edited_data" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import Cover from "../reports-shared-components/Cover";
import Page1 from "./report-content/Page1.vue";
import Page2 from "./report-content/Page2.vue";
import Page3 from "./report-content/Page3.vue";
import ReportEditedData from "../reports-shared-components/report-edited-data/ReportEditedData.vue";
import ReportActionButtons from "../reports-shared-components/ReportActionButtons.vue";
import SendReport from "../reports-shared-components/SendReport.vue";

export default {
	name: "AssetMonthlySavingsReport",
	components: {
		Alert,
		Loader,
		Cover,
		Page1,
		Page2,
		Page3,
		ReportEditedData,
		ReportActionButtons,
		SendReport
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object,
		report_edited_data: [Object, null]
	},
	data() {
		return {
			loading: null,
			error: null,
			show_report_comment: false,
			comment: null,
			downloading_pdf: false,
			loading_send_report: false,
			show_send_report_popup: false,
			report_file: null
		};
	},
	methods: {
		async download_pdf() {
			try {
				this.downloading_pdf = true;

				const custom_css_styles =
					"<style>.asset-monthly-savings-report-title { font-weight: bold; text-transform: uppercase; font-size: 17px; } .equivalent-box { background-color: #e6e6e7; border-radius: 5px; padding: 10px 50px 10px 15px; } .report-table { border-collapse: collapse; border: 1px solid #efeff0; font-size: 13px; } .report-table th, .report-table td { padding: 5px 5px; border: 1px solid #efeff0; } .report-table thead, .report-table td:first-child { background-color: #e6e6e7; }</style>";
				const html_element = this.$refs.pdf_content;
				await this.$download_asset_report_as_pdf_file(
					html_element,
					"asset-month-savings-report",
					custom_css_styles
				);
			} catch (error) {
			} finally {
				this.downloading_pdf = false;
			}
		},
		async share_pdf() {
			try {
				this.loading_send_report = true;

				const custom_css =
					"<style>.asset-monthly-savings-report-title { font-weight: bold; text-transform: uppercase; font-size: 17px; } .equivalent-box { background-color: #e6e6e7; border-radius: 5px; padding: 10px 50px 10px 15px; } .report-table { border-collapse: collapse; border: 1px solid #efeff0; font-size: 13px; } .report-table th, .report-table td { padding: 5px 5px; border: 1px solid #efeff0; } .report-table thead, .report-table td:first-child { background-color: #e6e6e7; }</style>";
				const ref_content = this.$refs.pdf_content;
				const pdf_file = await this.$get_asset_report_as_pdf_file(
					ref_content.innerHTML,
					"asset-month-savings-report",
					custom_css
				);
				this.report_file = pdf_file;

				this.show_send_report_popup = true;
			} catch (error) {
			} finally {
				this.loading_send_report = false;
			}
		},
		show_edit_report() {
			this.$emit("show_edit_report");
		},
		comment_edited(comment) {
			this.report_comment = comment;
		}
	},
	watch: {
		filter() {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 50);
		}
	}
};
</script>

<style lang="css" scoped>
#download-pdf-button {
	position: absolute;
	right: 15px;
	top: 25px;
	z-index: 1;
}
#report-comment {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.5);
}
#report-comment > div {
	width: 75%;
	margin-left: 12.5%;
	margin-top: 20px;
}
</style>

<style lang="css">
.asset-monthly-savings-report-title {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 17px;
}
</style>
