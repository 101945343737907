import EmailsSenderInterfaceClass from "../../shared/infrastructure/emails-sender-interface";
import AssetsReportsSenderValidator from "../domain/assets-reports-sender-validator.js";

import AssetFinderClass from "../../asset/application/asset-finder.js";
import MultilingualService from "../../../libs/application/multilingual-service.js";
import DataFormatterUtilClass from "../../shared/application/utils/data-formatter-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.emails_sender_interface = new EmailsSenderInterfaceClass();
		this.validator = AssetsReportsSenderValidator;

		this.asset_finder = new AssetFinderClass();
		this.multilingual_service = new MultilingualService();
		this.data_formatter_util = new DataFormatterUtilClass();
	}

	async send_assets_reports(report_data, report_file) {
		const validated_report_data = this.validator.validate(report_data);

		try {
			const assets_ids = report_data.assets_ids;
			const assets_names = [];
			for (let index in assets_ids) {
				const asset_id = assets_ids[index];
				const asset = await this.asset_finder.get_asset(asset_id);
				assets_names.push(asset.name);
			}

			const email_subject = this.multilingual_service.translate(
				"asset_reports.email_subject"
			);
			const html_email_content = this.multilingual_service.translate(
				"asset_reports.email_html_content",
				{
					assets_names: assets_names.join(", "),
					from: this.data_formatter_util.format_date_to_day(
						report_data.date_from
					),
					to: this.data_formatter_util.format_date_to_day(report_data.date_to),
					days_diff:
						Math.abs(
							moment(report_data.date_to).diff(report_data.date_from, "days")
						) + 1
				}
			);

			await this.emails_sender_interface.send_emails(
				email_subject,
				validated_report_data.emails,
				html_email_content,
				{
					file: report_file
				}
			);

			return true;
		} catch (e) {
			throw new AppError(
				"error_send_assets_reports",
				"general.errors.general_error",
				e
			);
		}
	}
}
