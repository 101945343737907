import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_energy_data_available_date_range(asset_id) {
		const url = "/api/asset/" + asset_id + "/energy-data/available-date-range";
		const backend_result = await this.get(url);

		return BackendObjectConverter(backend_result, {
			from: {
				formatter: (value) => new Date(value)
			},
			to: {
				formatter: (value) => new Date(value)
			}
		});
	}
}
